import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getRole } from './state/selectors/login'

// Layout
import Logo from './components/layout/logo'
import ProfileNavigation from './components/navigation/profile'
import LoginNavigation from './components/navigation/login'
import StyledHeader from './components/layout/header.styled'
import Footer from './components/layout/footer'
// Routing
import PrivateRoute from './components/routing/private'
// Pages
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import RecoverPage from './pages/recover'
import PlayCodePage from './pages/playcode/details'
import CreatePlayCodePage from './pages/playcode/create'
import GamesPage from './pages/game/games'
import GamePage from './pages/game/game'
import MyGamesPage from './pages/profile/games'
import MyPlayCodesPage from './pages/profile/playcodes'
import MyProfilePage from './pages/profile/profile'
import EditGamePage from './pages/game/edit'
import DuplicateGamePage from './pages/game/duplicate'
import DeleteGamePage from './pages/game/delete'
import PhysicalGuidePage from './pages/guides/physical'
import TournamentGuidePage from './pages/guides/tournament'
import InstallGuidePage from './pages/guides/install'


class App extends Component {
  
  render() {
    var { role } = this.props;
    return (
      <div>
        <div>
          <StyledHeader theme={process.theme}>
            <Logo />
            {role == "Profile" && <ProfileNavigation />}  
            {role != "Profile" && <LoginNavigation />}
          </StyledHeader>
          { role == "Profile" ?
          <div>
            {role == "Profile" && <PrivateRoute exact path='/' component={MyPlayCodesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/editgame' component={EditGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/creategame' component={EditGamePage} />}          
            {role == "Profile" && <PrivateRoute exact path='/createplaycode' component={CreatePlayCodePage} />}
            {role == "Profile" && <PrivateRoute exact path='/playcode' component={PlayCodePage} />}
            {role == "Profile" && <PrivateRoute exact path='/games' component={GamesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/game' component={GamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/register' component={RegisterPage} />}
            {role == "Profile" && <PrivateRoute exact path='/mygames' component={MyGamesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/profile' component={MyProfilePage} />}
            {role == "Profile" && <PrivateRoute exact path='/guide' component={PhysicalGuidePage} />}
            {role == "Profile" && <PrivateRoute exact path='/tournament' component={TournamentGuidePage} />}
            {role == "Profile" && <PrivateRoute exact path='/install' component={InstallGuidePage} />}
            {role == "Profile" && <PrivateRoute exact path='/duplicate' component={DuplicateGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/delete' component={DeleteGamePage} />}
          </div>
          :
          <div>
            {process.theme.publicgames &&
              <Route exact path='/games' component={GamesPage} />
            }
            {process.theme.publicgame &&
              <Route exact path='/game' component={GamePage} />
            }
            <Route exact path='/register' component={RegisterPage} />
            <Route exact path='/recover' component={RecoverPage} />          
            <Route exact path='/install' component={InstallGuidePage} />     
            <Route exact path='/guide' component={PhysicalGuidePage} /> 
            <Route exact path='/tournament' component={TournamentGuidePage} />
            {window.location.pathname != "/register" && (!process.theme.publicgame || window.location.pathname != "/game") && (!process.theme.publicgames || window.location.pathname != "/games") && window.location.pathname != "/recover" && window.location.pathname != "/register" && window.location.pathname != "/install" && window.location.pathname != "/guide" && window.location.pathname != "/tournament" && 
              <Route exact path='/*' component={LoginPage} />
            }
          </div>
          }
          <Footer/>
        </div>
      </div>
    )
  }
}


App.propTypes = {
  role: PropTypes.string.isRequired
}

App.defaultProps = {
  role: "",
}

const mapStateToProps = (state) => {
  return {
    role: getRole(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
