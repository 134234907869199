import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'

import EditGame from '../../components/pages/edit/game'

export class EditGamePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Създаване или актуализиране на игра"/>
          <EditGame />
      </div>
    )
  }
}

EditGamePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGamePage)
