import styled from 'styled-components'

const StyledProfile = styled.section`
#profile {

    .details {
        font-size: 1.2em;
    }

    .cancel {
        font-size: 0.7em;
        margin: 0 auto;
        padding-top: 20px;
        text-align: center;
        color: #F26173;
    }

    .reactivate {
        font-size: 0.7em;
        margin: 0 auto;
        padding-top: 20px;
        text-align: center;
        color: #009EC0;
    }

    .deactive {
        color: #F26173;
        font-weight: bold;
    }

    .active {
        color: #3a953c;
        font-weight: bold;
    }

    .congrats {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        padding-bottom: 20px;
        color: #3a953c;
    }   
    
    .fail {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        padding-bottom: 50px;
        color: #f26173;
    }   

    .pending {
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        padding-bottom: 50px;
    }   

    .getinvoice {
        padding-bottom: 50px;
        text-align: center;
    }

    .detail {
        margin: 15px auto;
  
        @media (min-width: 1200px) {
          display: flex;
          width: 1200px;
        }
      }
  
    .attribute {
    font-weight: bold;
    color: ${props => props.theme.detailColor};

    @media (min-width: 1200px) {
        text-align: right;
        padding-right: 20px;
        width: 500px;
    }
    }

    .buttons {
        padding-top:40px;
    }
    .value {
        @media (min-width: 1200px) {
            text-align: left;
            width: 500px;
        }
    }

    .description {
        text-align: center;
        font-size: 1.3em;
    } 
}
`
export default StyledProfile
