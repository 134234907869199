
import * as types from './types'

export function createPlayCode(subscriptionId, profileId, gameId, theme, minutes, isVirtual, isConference, conferencePosition) {
  return {
    type: types.CREATE_PLAYCODE_REQUEST,
    payload: { subscriptionId, profileId, gameId, theme, minutes, isVirtual, isConference, conferencePosition }
  }
}

export function readPlayCode(id) {
  return {
    type: types.READ_PLAYCODE_REQUEST,
    payload: { id }
  }
}

export function resetPlayCode() {
  return {
    type: types.RESET_PLAYCODE
  }
}

export function readPlayCodes(profileId) {
  return {
    type: types.READ_PLAYCODES_REQUEST,
    payload: { profileId }
  }
}

export function readLastPlayCode(profileId) {
  return {
    type: types.READ_LAST_PLAYCODE_REQUEST,
    payload: { profileId }
  }
}