import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import history from './history'
import Favicon from 'react-favicon';

import App from './app'
import { Provider } from 'react-redux'
import storage from './state/storage'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { bgBG } from '@material-ui/core/locale';

const theme = createMuiTheme({}, bgBG);

const store = storage()

//persistStore(store)

require('./index.css');

process.theme = {
  name: "puk",
  url: "https://kod.puk.bg",
  headerBgrColor: "#fff",
  headerColor: "#000",    
  footerBgrColor: "#009EC0",
  titleBgrColor: "#009ec0",
  titleColor: "#fff", 
  subTitleColor: "#f26173",  
  defaultColor: "#009EC0", 
  alternativeColor: "#f26173",
  font: '"HKGrotesk-Regular", sans-serif',
  fontBold: '"HKGrotesk-Bold", sans-serif',
  buttonDefaultBgrColor: "#009EC0",
  buttonDefaultColor: "#fff",
  buttonBackBgrColor: "#e8ebe2",
  buttonBackColor: "#000",
  buttonCancelBgrColor: "#e8ebe2",
  buttonCancelColor: "#000",
  buttonDeleteBgrColor: "#f26173",
  buttonDeleteColor: "#fff",   
  buttonSubscribeBgrColor: "#734072",
  buttonSubscribeColor: "#fff",  
  buttonGenerateBgrColor: "#734072",
  buttonGenerateColor: "#fff",  
  title: "Порталът за учители на Пук.бг | За деца, които са загрижени!",
  favicon: "https://puk.bg/favicon.ico",
  address: "ул. Розова долина 5, Лозенец, гр. София",
  email: "contact@caringers.com",
  phone: "+359 893 691 583",
  contactURL: "https://caringers.com/en/contact",
  subject: "Учебен предмет",
  playerURL: "https://kod.puk.bg",
  teamURL: "https://kod.puk.bg/team",
  monitorURL: "https://kod.puk.bg/monitor",
  dashboardURL: "https://kod.puk.bg/dashboard",
  publicgames: true,
  publicgame: true,
  themes: [
    <option value="" disabled>---- За детска градина ----</option>,
    <option value="7">Прахопукитата</option>,
    <option value="6">Нефтопукитата</option>,
    <option value="" disabled>---- За начален етап ----</option>,
    <option value="1">Нефтопукльовците</option>,
    <option value="2">Прахопукльовците</option>,
    <option value="3">Бокльопукльовците</option>,
    <option value="4">Торопукльовците</option>,
    <option value="5">Лесопукльовците</option>,
    <option value="8">Биопукльовците</option>,
    <option value="9">Диоксипукльовците</option>,
    <option value="10">Пластопукльовците</option>,
    <option value="11">Хранопукльовците</option>
  ]
}

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <Favicon url={process.theme.favicon} />
          <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'))
