import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'

import Games from '../../components/pages/game/games'

export class GamesPage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Списък с всички игри"/>
        <StandardSection bgr="#FFF">
          <Content>
            <Games />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

GamesPage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesPage)
