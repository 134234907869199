import styled from 'styled-components'

const StyledHeader = styled.header`

 
 @media (min-width: 1200px) {
    display: flex;
    background-color: ${props => props.theme.headerBgrColor};
    position: fixed;
    top: 0;
    width: 100%;
    height: 110px;
    align-items: center;
    z-index: 1;
}

@media print {
   display: none!important;
}
`

export default StyledHeader
