import styled from 'styled-components'

const StyledGames = styled.section`
.filter {
    padding-top: 10px;

    @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#games {
    margin-top: 0px;
}

.topic {
    font-weight: bold;
    margin-top: 10px;
}

.isplayed {
    background: #E8E8E8;
}

.filters {
    @media (min-width: 1200px) {
        padding-left: 10px;
    }
}

.filterTitle {
    font-size: 1.5em;
    color: ${props => props.theme.defaultColor};
}

.chips {    
    margin-bottom: 60px;
}

.chip_clear {
    margin-top: 10px !important;
}

.buttons {
    padding-top: 40px;
}

.create {
    padding-bottom: 7px;
}

.create a {
    padding: 0px 0px 7px 0px;
}

.free_play {
    color: #3a953c;
}

@media (min-width: 1200px) {

    .select {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 50px;
    }
}
`
export default StyledGames
