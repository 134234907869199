import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'
import SectionTitle from '../../components/ui/subtitle'
import Profile from '../../components/pages/profile/profile'

import {  getProfile } from '../../state/selectors/profile'

export class ProfilePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Моят акаунт"/>
        <StandardSection bgr="#e8e8e8">
          <Content>
            <SectionTitle text="Вашата лична информация"/>
            <Profile />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

Profile.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
