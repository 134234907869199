import React from 'react'
import StyledPlayer from './player.styled'
import Unity, { UnityContext } from "react-unity-webgl";
import queryString from 'query-string';
import PageVisibility from 'react-page-visibility';

class Player extends React.Component {

  state = {
    progression : 0,
    isLoaded : false,
    accessKey: -1,
  }

  componentWillMount() {
    let url =  window.location.href;
    window.alert = console.log;
    const parsed = queryString.parseUrl(url);
    // Check for access key
    this.accessKey = (parsed.query.key) ? parsed.query.key : -1;
    if (this.accessKey == -1) {
      this.accessKey = (parsed.query.Key) ? parsed.query.Key : -1;
    }
    this.setState({
      accessKey: this.accessKey,
      innerHeight: window.innerHeight,
    })
  }

  constructor(props) {
    super(props);

    this.unityLang = "bg";

    this.unityContext = new UnityContext({
      loaderUrl: "../unity/Build/Build.loader.js",
      dataUrl: "../unity/Build/Build.data",
      frameworkUrl: "../unity/Build/Build.framework.js",
      codeUrl: "../unity/Build/Build.wasm",
      streamingAssetsUrl: "../unity/StreamingAssets/"
    });
    
    this.unityContext.on("progress", progress => {
      this.setState(
        {
          progression : Math.floor(progress * 100),
        }
      );
    });
    
    this.unityContext.on("loaded", () => {
      if (this.props.isHub) {
        setTimeout(function() {
            this.unityContext.send(
              "Web", 
              "StartGameByHub",
              this.unityLang + "|" + this.props.gameId + "|" + localStorage.getItem('token'),
            );
            this.setState(
            {
              isLoaded : true
            }
            );
          }
        .bind(this), 0);
      } if (this.props.isDemo) {
        setTimeout(function() {
            this.unityContext.send(
              "Web", 
              "StartDemo",
              this.unityLang + "|" + this.state.demoPlayers
            );
            this.setState(
            {
              isLoaded : true
            }
            );
          }
        .bind(this), 0);
      } else {
        this.setState(
          {
            isLoaded : true
          });
      } 
    });
  }

  isWebGLAvailable = () => {
    return this.isWebGL1Available() || this.isWebGL2Available();
  }

  isWebGL1Available = () => {
		try {
			var canvas = document.createElement( 'canvas' );
			return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
		} catch ( e ) {
			return false;
		}
  }
  
	isWebGL2Available = () => {
		try {
			var canvas = document.createElement( 'canvas' );
			return !! ( window.WebGL2RenderingContext && canvas.getContext( 'webgl2' ) );
		} catch ( e ) {
			return false;
		}
  }

  render() {
    this.unityLang = "bg-BG";


    return (
      <StyledPlayer>
        <PageVisibility onChange={this.handleVisibilityChange}>
        <div class="player">
          {!this.isWebGLAvailable && <div class="nounity">За съжаление Вашият браузър или устройство <b>не поддържа WebGL</b>, който е нужен, за да играете с Пук.бг онлайн. Опитайте с друг браузър или разгледайте игрите ни офлайн.</div>}
          {this.isWebGLAvailable &&
          <div>
            <div {...(this.state.conferencePos == "right" ? {class: "plot"} : {})}>
              <div {...(this.state.conferencePos == "right" ? {class: "rightunity"} : {class: "topunity"})}>
                {this.state.progression < 100 && <div class="lprogress">{this.state.progression}%</div>}
                {<Unity unityContext={this.unityContext} 
                style={{
                  height: "100%",
                  width: "100%",
                }}
                
                />}
              </div>
            </div>
          </div>
          }
        </div>     
        </PageVisibility>
      </StyledPlayer>
      )
    }
}
export default Player