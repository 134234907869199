import React from 'react'
import Requirements from './requirements'
import Requirement from './requirement'

class AppRequirements extends React.Component {

  state = {
    expanded: false,
  }

  onToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
  return (
        <Requirements title="Какви са техническите изисквания, за да ползвате приложението на Пук.бг?">
          <Requirement attention="Препоръчителни са таблети (като на 1 таблет могат да играят до 6 човека).">Устройство със <b>сензорен екран</b> (touch screen), който е <b>поне 7 инча</b> (за да има достатъчно място 6 човека да се подредят в кръг около него).</Requirement>
          <Requirement attention="Приоложението не е достъпно за iOS.">Операционна система <b>Android OS</b> с версия 5.1 или по-нова.</Requirement>
          <Requirement>Достъп до <b>интернет</b>.</Requirement>        
        </Requirements>
    )
  }
}
export default AppRequirements
