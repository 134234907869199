import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'
import PlayCodе from '../../components/pages/playcode/playcode'

export class PlayCodePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Преглед на код за игра"/>
        <StandardSection bgr="#FFF">
          <PlayCodе isGoBack={true} />
        </StandardSection>
      </div>
    )
  }
}

PlayCodePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayCodePage)
