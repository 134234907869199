import React, { Component } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { isAuthenticated } from '../../state/selectors/login'

export class PrivateRoute extends Component {

  render() {
    let { component: Component, isLogged, ...rest } = this.props

    return (
      <Route {...rest} render={props => (isLogged ? (<Component {...props} />) : (
          <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }}/>
        )
      )}/>
    )
  }
}

PrivateRoute.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  component: PropTypes.func
}

PrivateRoute.defaultProps = {
  isLogged: false,
}

const mapStateToProps = (state) => {
  return {
    isLogged: isAuthenticated(state)
  }
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
