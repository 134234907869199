import styled from 'styled-components'

const StyledRequirements = styled.div`

.requirements {
  background: #999;
  color: #fff;
  padding: 5px 15px;
  border-radius: 25px;
}

.title {
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  font-weight: bold;
}

.item {
  background: #fff;
  color: #000;
  padding: 5px 15px;
  border-radius: 25px;
  text-align: center;
  margin: 20px 0;
}

.requirement {
  margin: 20px 0;
}
.attention {
  margin-bottom: 20px;
}

.expanded {
  display: block;
}

.notexpanded {
  display: none;
}

@media print {
  .notexpanded {
    display: block;
  }
}
  
`

export default StyledRequirements
