import React from 'react'
import StyledFixedButton from './fixedbutton.styled'

class FixedButton extends React.Component {
  
  render() {
  return (
    <StyledFixedButton>
      <div class="fixedbutton">
        {this.props.children}
      </div>
    </StyledFixedButton>
    )
  }
}
export default FixedButton
