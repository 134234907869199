import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import history from '../../../history'
import parse from 'html-react-parser';

import StyledGame from './game.styled'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

import {  getGame } from '../../../state/selectors/game'
import {  getProfile } from '../../../state/selectors/profile'
import { readGame, resetGame } from '../../../state/actions/game'

import Tablet from '../../ui/tablet'
import Player from '../../ui/player'
import Theme from '../../ui/theme'

import Level from './level'

import queryString from 'query-string'

export class Game extends Component {
  
  constructor(props) {
    super()

    props.fetchResetGame();

    const values = queryString.parse(window.location.search);
    if (values.gameId != null && values.gameId != "") {
      props.fetchReadGame(values.gameId);
      this.state = {
        gameId: values.gameId,
      }
    } else if (values.gameid != null && values.gameid != "") {
      props.fetchReadGame(values.gameid);
      this.state = {
        gameId: values.gameid,
      }
    } else if (values.id != null && values.id != "") {
      props.fetchReadGame(values.id);
      this.state = {
        gameId: values.id,
      }
    }
  }
  
  goBack = () => {
    history.go(-1);
  }

  render() {
      const { game, profile } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      let resetingGame = this.state.gameId != "" && this.state.gameId != gameId;
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const grade = (game.getIn(['Game', 'Grade'], null) == null) ? "" : game.getIn(['Game', 'Grade'], null);
      const complexity = (game.getIn(['Game', 'Complexity'], null) == null) ? "" : game.getIn(['Game', 'Complexity'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "-" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "-" : game.getIn(['Game', 'GroupInstruction'], null);
      const author = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      const isFree = (game.getIn(['Game', 'IsFree'], null) == null) ? "" : game.getIn(['Game', 'IsFree'], null);
      const organization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      const theme = (game.getIn(['Game', 'Theme'], null) == null) ? "0" : game.getIn(['Game', 'Theme'], null);
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? "" : game.getIn(['Game', 'Levels', 'Level'], null);
      if (!levels) {
        levels = new Map();
      }
      var complexityLabel = "";
      switch (complexity) {
          case "1": {
            complexityLabel = "Много лесно";
            break;
          }
          case "2": {
            complexityLabel = "Лесно";
            break;
          } 
          case "3": {
            complexityLabel = "Трудно";
            break;
          } 
          case "4": {
            complexityLabel = "Много трудно";
            break;
          } 
      }
      const gameProfileId = game.getIn(['Game', 'Profile', 'Id'], null);
      const profileId = profile.getIn(['Profile', 'Id'], null);
      const subscription = profile.getIn(['Profile', 'Subscription'], null);
      var isActive = (subscription) ? subscription.getIn(["IsActive"]) : false;
      
      return (
        <StyledGame theme={process.theme}>
        {gameId == null && 
          <div class="nogame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
        }
        {resetingGame && gameId != null &&
          <div class="resetinggame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
        }
        {!resetingGame && gameId != null &&
          <div id="gamedetails">
            <div>
              <div id="info">
                <div class="detail">
                    <div class="title">Номер на играта: </div>
                    <div class="value"><b>{gameId}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Заглавие: </div>
                    <div class="value">{title}</div>
                </div>
                <div class="detail">
                    <div class="title">Кратко описание: </div>
                    <div class="value">{description}</div>
                </div>
                <div class="detail">
                    <div class="title">{process.theme.subject}:</div>
                    <div class="value">{subject}</div>
                </div>
                <div class="detail">
                    <div class="title">Подходяща за: </div>
                    <div class="value">{grade}</div>
                </div>
                <div class="detail">
                    <div class="title">Ниво на сложност: </div>
                    <div class="value">{complexityLabel}</div>
                </div>
                <div class="detail">
                    <div class="title">Инструкции за индивидуалните задачи: </div>
                    <div class="value">{individualInstruction}</div>
                </div>
                <div class="detail">
                      <div class="title">Инструкции за груповите задачи: </div>
                      <div class="value">{groupInstruction}</div>
                </div>
                {theme != "0" &&
                  <div class="detail">
                      <div class="title">Графична тема на играта: </div>
                      <div class="value">
                        <div class="theme">
                          <Theme id={theme} />
                        </div>
                      </div>
                  </div>
                }
                <div class="detail">
                    <div class="title">Автор: </div>
                    <div class="value">{author}</div>
                </div>
                <div class="detail">
                    <div class="title">Организация: </div>
                    <div class="value">{organization}</div>
                </div>
              </div>
            </div>

            

            {(subscription != null || isFree) &&
              <div class="levels">
                <div class="levelstitle"><h4>Нива на играта:</h4></div>
                {
                  levels.sort((a, b) => a.getIn(['SequenceNumber'], null) - b.getIn(['SequenceNumber'], null)).map((val, idx)=> {
                    return (
                      <div class="level_component">
                        <Level gameId={gameId} level={val} id={idx} sequence={idx} />
                      </div>
                    )
                  })
                }
              </div>
            }
            
            {(subscription != null || isFree) &&
            <div class="demo">
              <div class="player">
                <Tablet>
                  <Player isHub gameId={gameId} />
                </Tablet>
              </div>
            </div>
            }

            <div class="buttons">
              {profileId != null && gameProfileId != null && gameProfileId == profileId &&
                <div class="edit">
                <FixedButton>
                  <StandardButton text="Актуализирайте" width="100%" href={'/editgame?id=' + gameId} />
                </FixedButton>
              </div>
              }
              {profileId != null && subscription == null && !isFree &&
              <div class="subscribe">
                <div class="invite">Искате да достъпите цялата информация за играта, както и да я тествате и играете?</div>
                <div>
                  <FixedButton>
                    <StandardButton href="/profile#plans" text="Купете си абонамент" width="100%" type="subscribe"/>
                  </FixedButton>
                </div>
              </div>
              }
              {profileId != null && (subscription != null || isFree) &&
                <div class="generate">
                  <FixedButton>
                    <StandardButton isDisabled={!(isActive || isFree)} text="Генерирайте код за игра" width="100%" href={'/createplaycode?gameId=' + gameId} type="generate" />
                  </FixedButton>
                </div>
              }
              {profileId != null && subscription != null &&
                <div class="duplicate">
                  <FixedButton>
                    <StandardButton text="Дублирайте" width="100%" href={'/duplicate?gameId=' + gameId} />
                  </FixedButton>
                </div>
              }
              {profileId != null &&
                <div class="back">
                  <FixedButton>
                    <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                  </FixedButton>
                </div>
              }
            </div>
          </div>
        }
      </StyledGame>
    )
  }
}

Game.propTypes = {
  game: PropTypes.object,
  profile: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
    profile: getProfile(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
   fetchReadGame: (gameId) => dispatch(readGame(gameId)),   
   fetchResetGame: () => dispatch(resetGame())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)
