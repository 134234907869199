import { fromJS, Map } from 'immutable'


export function getProfile(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile'], null)
}

export function setProfile(state, newState) {
  if (newState.profile.Error) {
    localStorage.setItem("role", "");
  } else {
    localStorage.setItem("role", "Profile");
  }
  return state.merge(fromJS(newState.profile))
}

export function setError(state, error) {
  localStorage.setItem("role", "");
  return state.merge({
    ApiError: fromJS(error)
  })
}
