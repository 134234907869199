import React from 'react'
import StyledSection from './section.styled'

export default function StandardSection({
  children,
  bgr,
  noPrint
}) {
  return (
    <StyledSection noPrint={noPrint}>
      <div class="section" style={{ background: bgr}}>      
        {children}
      </div>
    </StyledSection>
  )
}
