import React from 'react'
import YouTube from 'react-youtube';
import StyledVideo from './video.styled'

class Video extends React.Component { 
  render() {

    if (window.innerWidth > 1200) {
      this.opts = {
        height: '500',
        width: '888'
      };
    } else {
      this.opts = {
        height: (window.innerWidth - 100) * 0.56,
        width: window.innerWidth - 100
      }
    }
    
    return (
      <StyledVideo>
        <section className="youtube">
          <YouTube videoId={this.props.id} opts={this.opts}/>
        </section>
      </StyledVideo>
      )
  }
}
export default Video
