import styled from 'styled-components'

const StyledVideo = styled.div`

.youtube {
  padding: 80px 0;
  text-align: center;
  width: 100%;
}

@media (min-width: 1200px) {
  
}

@media print {
  .youtube {
    display: none;
  }
}
  
`

export default StyledVideo
