import React from 'react'
import Requirements from './requirements'
import Requirement from './requirement'

class HubRequirements extends React.Component {

  state = {
    expanded: false,
  }

  onToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
  return (
        <Requirements title={"Какви са техническите изисквания, за да ползвате " + process.theme.url + "?"}>
          <Requirement>Достъп до <b>интернет</b>.</Requirement>
          <Requirement attention="Много е вероятно да срещнете проблеми, ако ползвате Internet Explorer.">Един от следните браузъри: <b><a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Mozilla Firefox</a>, Safari или Microsoft Edge</b>.</Requirement>
          <Requirement attention="Това условие е необходимо, за да може да ползвате игрите през платформата онлайн. Цялата останала функционалност остава налична и без WebGL.">Браузър, който поддържа технологията <b>WebGL</b> (проверете на <a href="https://get.webgl.org/" target="_blank">https://get.webgl.org/</a>).</Requirement>        
        </Requirements>
    )
  }
}
export default HubRequirements
