import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'
import RecoverForm from '../components/pages/recover/form'

export class RecoverPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Забравена парола"/>
        <StandardSection bgr="#FFF">
          <Content>
            <RecoverForm />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

RecoverPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPage)
