import { Map } from 'immutable'

export function getRole(state) {
  const immutableState = Map(state)
  var role = "";
  if (immutableState.getIn(['profile', 'Profile'], null) !== null) {
    role = "Profile";
  }
  return role;
}

export function isAuthenticated(state) {
  const immutableState = Map(state);
  return immutableState.getIn(['profile', 'Profile'], null) !== null;
}

export function getProfileId(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Profile', 'Id'], null)
}

export function hasSubscription(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Profile', "Subscription"], null)
}

export function isProfileError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Error'], null) !== null
}

export function isProfileApiError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'ApiError'], null) !== null
}