import React from 'react'
import StyledGuide from '../guide.styled'
import StyledPhysical from './physicalgame.styled'
import Section from '../../../ui/section'
import GuideSubTitle from '../subtitle'
import Content from '../../../ui/content'
import AppRequirements from './requirements/app'
import OnlineRequirements from './requirements/online'
import Video from '../video'

class PhysicalGame extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <StyledPhysical>
        <Section bgr="#FFF">
          <GuideSubTitle text="По време на часа"/>
          <Content>
            <h3>Стъпка 1: РАЗДЕЛЕТЕ ИГРАЧИТЕ В ОТБОРИ ОТ 2 ДО 6 ЧОВЕКА</h3>          
              
            <h3>Стъпка 2: ВЛЕЗТЕ В АКАУНТА СИ, ЗА ДА ДОСТЪПИТЕ КОДА ЗА ИГРА*</h3>
            <div class="asterix">*в случай, че не сте разпечатали кода за игра и разполагате със смарт телефон с интернет</div>
            <div class="section2">
              <div class={["right", "tablet2"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen1_1.png" alt="Моите генерирани кодове"/>
                <div class={["podlojka", "p1_1"].join(' ')}><div>1</div></div>  
                <div class={["podlojka", "p1_2"].join(' ')}><div>2</div></div>  
                <div class={["podlojka", "p1_3"].join(' ')}><div>3</div></div>  
                <div class={["podlojka", "p1_4"].join(' ')}><div>4</div></div>   
              </div>
              <div class="left">    
                <h4>1. Отворете <a href="https://kod.puk.bg/" target="_blank">https://kod.puk.bg/</a> и влезте в своя профил</h4>
                <h4>2. Последно генериран активен код за игра</h4>
                <div>Веднага щом влезете в профила си ще видите последно генерирания и все още активен код за игра.</div>
                <div><span class="info">Инфо:</span> 
                Ако сте генерирали няколко кода за игра и всички те са активни – може да ги достъпите като скролнете надолу в страницата. Ще ги видите в списък, от който може да изберете кода за игра, които Ви интересува, като натиснете „Разгледайте кода за игра“.
                <div class="codes"><img src="/images/guides/physicalgame/screen1_2.png" alt="Списък с кодове за игра"/></div>
                </div>
                <h4>3. QR код</h4>
                <div>Използвайте този код, ако искате да сканирате параметрите на играта през камерата на устройството, на което ще играете.</div>
                <h4>4. Ръчен код за игра</h4>
                <div>Използвайте този код, ако ще въведете параметрите на играта ръчно.</div>
              </div>          
            </div>

            <h3>Стъпка 3: СТАРТИРАЙТЕ ИГРАТА НА ТАБЛЕТИТЕ</h3>
            <div class="tablets">Всеки отбор трябва да разполага с устройство със сензорен екран (напр. таблет), на което е инсталирано приложението на Пук.бг (вижте как да направите това на <a href='https://kod.puk.bg/install' target='_blank'>https://kod.puk.bg/install</a>).</div>
            <div class="appreq"><AppRequirements /></div>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen2_1.jpg" alt="Сканиращ екран"/>
                <div class={["podlojka", "p2_1_1"].join(' ')}><div>1</div></div>
              </div>
              <div class="left">    
                <h4>1. Приложението Пук.бг</h4>
                <div>Открийте и стартирайте приложението Пук.бг на таблета.</div>
              </div>
            </div>

            <h3>Стъпка 4: СКАНИРАЙТЕ / ВЪВЕДЕТЕ КОДА ЗА ИГРA НА ВСЕКИ ОТ ТАБЛЕТИТЕ</h3>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen2.jpg" alt="Сканиращ екран"/>
                <div class={["podlojka", "p2_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p2_2"].join(' ')}><div>6</div></div>
                <div class={["podlojka", "p2_3"].join(' ')}><div>7</div></div>
                <div class={["podlojka", "p2_5"].join(' ')}><div>4</div></div>
                <div class={["podlojka", "p2_6"].join(' ')}><div>5</div></div>
                <div class={["podlojka", "p2_9"].join(' ')}><div>3</div></div>
                <div class={["podlojka", "p2_10"].join(' ')}><div>2</div></div>
              </div>
              <div class="left">    
                <h4>1. Камера</h4>
                <div>Сканира QR кода, който предварително сте генерирали през профил си или сте получители при регистрация за турнир. При успех – стартира играта.</div>
                <h4>2. Достъп с ръчен код / ръчен безплатен код</h4>
                <div>Позволява да въведете ръчния код, който предварително сте генерирали през профила си или сте получили при регистрация за турнир. При успех – стартира играта.</div>
                <h4>3. „Демо“ режим</h4>
                <div>Позволява демонстриране или тестване на основните функционалности на Пук.бг.</div>
                <h4>4. Инсталирана версия</h4>
                <div>Показва номера на инсталираната от Вас версия. При натискане води към <a href="https://puk.bg/bg/packages">https://puk.bg/bg/packages</a>, където имате възможност да обновите своята версия, в случай че тя не е актуална.</div>
                <div><span class="info">Инфо:</span>При стартиране на Пук.бг, играта автоматично проверява коя е нейната последна актуална версия. Ако тя се различава от тази, която Вие имате инсталирана – иконката променя цвета си в червено и се появява балон с текст. В този случай ние препоръчваме да актуализирате своята версия като натиснете върху балона с текст.</div>
                <h4>5. Смяна на камера</h4>
                <div>В случай че Вашето устройства разполага с две камери – дава възможност да смените камерата, с която сканирате QR кода.</div>
                <h4>6. Помощ</h4>
                <div>Води към настоящето „Ръководства за ползване на Пук.бг“</div>
                <h4>7. Изход</h4>
                <div>Затваря приложението Пук.бг.</div>
              </div>
            </div>

            <h3>Стъпка 5: РАЗДАЙТЕ ПО ЕДИН ТАБЛЕТ НА ВСЕКИ ОТБОР</h3>
            
            <h3>Стъпка 6: ИЗБОР НА БРОЯ НА ИГРАЧИТЕ В ОТБОРА</h3>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen3.jpg" alt="Начален екран"/>
                <div class={["podlojka", "p3_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p3_2"].join(' ')}><div>2</div></div>
              </div>
              <div class="left">    
                <h4>1. Бутони от 1 до 6</h4>
                <div>Определят големината на отбора – може да се състой от най-много 6 ученици.</div>
                <h4>2. Старт</h4>
                <div>Поканете отборите да изберат своята големина и им дайте знак, когато да започнат играта като натиснат този бутон.</div>
              </div>
            </div>

            <h3>Стъпка 7: НАСЪРЧЕТЕ ИГРАЧИТЕ ДА ОЦЕНЯТ ЧУВСТВАТА СИ В НАЧАЛОТО НА ИГРАТА</h3> 
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen4.jpg" alt="Оценка на емоциите"/>
                <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
              </div>
              <div class="left">    
                <h4>1. Оценка на емоциите на всеки играч</h4>
                <div>Дава възможност на всеки играч да оцени как се чувства в началото на играта. Жълтото усмихнато лице означава, че ученикът се чувства добре, а синьото натъжено лице – че не се чувства добре (напр. е тъжен, гневен, разочарован и др.).</div>
              </div>
            </div>

            <h3>*Стъпка 8: ОСТАВЕТЕ ИГРАЧИТЕ ДА СЕ ЗАПОЗНАЯТ С ИНСТРУКЦИИТЕ НА ИГРАТА</h3> 
            <div class="asterix">*в случай, че играта има инструкции преди играта</div>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen10.jpg" alt="Инструкции за играта"/>
                <div class={["podlojka", "p10_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p10_2"].join(' ')}><div>2</div></div>
                <div class={["podlojka", "p10_3"].join(' ')}><div>3</div></div>
              </div>
              <div class="left">    
                <h4>1. Инструкции</h4>
                <div>Някои игри имат специфични инструкции, с които играчите трябва да се запознаят.</div>
                <h4>2. Скрол бар</h4>
                <div>Скрол барът Ви позволява да достъпите целите инструкции тогава, когато те са твърде много, за да се съберат на екрана.</div>
                <h4>3. Готово</h4>
                <div>Отборът следва да натисне готово, когато се запознае с инструкциите.</div>
              </div>
            </div>

            <h3>Стъпка 9: НАБЛЮДАВАЙТЕ ИГРАТА И ЕКИПНАТА РАБОТА НА ИГРАЧИТЕ</h3>
            <div class="start">Основната задача на обучителя е да наблюдава екипната работа на участниците, за да може да им даде ефективна обратна връзка по време на анализа на играта.</div>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen5.jpg" alt="Игрови екран"/>
                <div class={["podlojka", "p4_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p4_2"].join(' ')}><div>2</div></div>
                <div class={["podlojka", "p4_3"].join(' ')}><div>3</div></div>
                <div class={["podlojka", "p4_4"].join(' ')}><div>4</div></div>
                <div class={["podlojka", "p4_5"].join(' ')}><div>5</div></div>
              </div>
              <div class="left">    
                <h4>1. Текстове, разпределни на случаен принцип между играчите</h4>
                <h4>2. Подложка с допълнителни инструкции, свързани с текстовете</h4>
                <h4>3. Възможни отговори</h4>
                <div>Играта изисква от играчите да си сътрудничат и да работят в екип, за да октрият отговорът на задачата в нивото (който обикновено е следствие от текстовете, разпределени между играчите).</div>
                <h4>4.Подложка с допълнителни инструкции, свързани с възможните отговори</h4>
                <h4>5. Оставащо време до края на играта</h4>
              </div>
            </div>

            <h3>Стъпка 10: НАСЪРЧЕТЕ УЧЕНИЦИТЕ ДА ОЦЕНЯТ ЧУВСТВАТА СИ В КРАЯ НА ИГРАТА</h3>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen7.jpg" alt="Оценка на емоциите"/>
                <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
              </div>
              <div class="left">    
                <h4>1. Оценка на емоциите на всеки играч</h4>
                <div>Дава възможност на всеки играч да оцени как се чувства в края на играта. Жълтото усмихнато лице означава, че ученикът се чувства добре, а синьото натъжено лице – че не се чувства добре (напр. е тъжен, гневен, разочарован и др.).</div>
              </div>
            </div>

            <h3>Стъпка 11: АНАЛИЗИРАЙТЕ РЕЗУЛТАТА ОТ ИГРАТА</h3>
            <div class="start">Много е важно учителят да даде обратна връзка на учениците за екипната им работа, за да им помогне да осмислят социалния опит, който са придобили чрез играта. Може да използва нашето методическо ръководство за управлява ефективно този процес.</div>
            <div class="section1">
              <div class={["right", "tablet1"].join(' ')}>
                <img class="screen" src="/images/guides/physicalgame/screen8.jpg" alt="Екран с резултата от играта"/>
                <div class={["podlojka", "p8_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p8_2"].join(' ')}><div>2</div></div>
                <div class={["podlojka", "p8_3"].join(' ')}><div>3</div></div>
                <div class={["podlojka", "p8_5"].join(' ')}><div>4</div></div>
                <div class={["podlojka", "p8_6"].join(' ')}><div>5</div></div>
              </div>
              <div class="left">    
                <h4>1. Резултат</h4>
                <div>Показва резултата на отбора. </div>
                <div><span class="info">Инфо:</span> Максималният резултат е 100 и означава, че учениците са решили без грешка всички въпроси.</div>
                <h4>2. Показател за настроението в екипа</h4>
                <div>Показва какво е настроението в екипа – как са се чувствали играчите в края на играта. Колкото повече са червените сърца, толкова повече положителни емоции са преобладавали в екипа по време на играта.</div>
                <div><span class="info">Инфо:</span> Настроението на екипа се измерва на базата на индивидуалните оценки на чувствата на играчите в края на играта.</div>
                <h4>3. Нови Пукльовци</h4>
                <div>След пукането на Пукльото – той се размножава и се появяват нови Пукльовци, които продължават неговата мисия по опазване на околната среда!</div>
                <h4>4. Връщане на началния екран</h4>
                <div>Натиснете този бутон, ако искате да стартирате нова игра.</div>
                <h4>5. Затваране на приложението Пук.бг</h4>
                <div>Натиснете този бутон, ако искате да затворите игра.</div>
              </div>
            </div>
          </Content>
        </Section>
      </StyledPhysical>
    </StyledGuide>
    )
  }
}
export default PhysicalGame
