import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../../../history'

import StyledPlayCode from './playcode.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getGame } from '../../../state/selectors/game'
import {  getProfile } from '../../../state/selectors/profile'
import {  getPlayCode } from '../../../state/selectors/playcode'
import { readGame } from '../../../state/actions/game'
import { readPlayCode, readLastPlayCode, resetPlayCode } from '../../../state/actions/playcode'

import queryString from 'query-string'

var QRCode = require('qrcode.react');

function msToTime(duration) {
  if (!duration) {
    return "-";
  }
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  var seconds = Math.floor((duration / 1000) % 60),
  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
}

export class PlayCode extends Component {
  
  constructor(props) {
    super()

    this.state = {
      isAddingTeam: false,
      isFetchingGame: false,
    }

    const { playCode, profile } = props;
    var urlId = "";
    if (window.location) {
      const values = queryString.parse(window.location.search);
      if (values.Id != null && values.Id != "") {
        urlId = values.Id;
      } else if (values.id != null && values.id != "") {
        urlId = values.id;
      }
    }
    var id = playCode.getIn(['PlayCode', 'Id'], null);
    
    if (urlId != "") {
      props.fetchReadPlayCode(urlId);
    } else if (id == null) {
      const profileId = profile.getIn(['Profile', 'Id'], null);
      props.fetchReadLastPlayCode(profileId);
    }

    this.print = this.print.bind(this);
  }
  
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ timeLeft: msToTime() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    this.setState({
      [ id ]: value,
      isChanged: true,
    });
  }

  print = () => {
    window.print();
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      const { playCode, isGoBack, game } = this.props;
      var id = playCode.getIn(['PlayCode', 'Id'], null);  
      var hasPlayCode = (id != null);  
      var gameId = playCode.getIn(['PlayCode', 'GameId'], null);
      var gameNotLoaded = gameId != null && gameId != game.getIn(['Game', 'Id'], null);
      if (gameNotLoaded && !this.state.isFetchingGame) { 
        // Fetch game
        this.state.isFetchingGame = true;
        this.props.fetchReadGame(gameId);
      }
      var minutes = playCode.getIn(['PlayCode', 'Minutes'], null);
      var theme = playCode.getIn(['PlayCode', 'Theme'], null);
      var accessKey = playCode.getIn(['PlayCode', 'AccessKey'], null);
      var encryptedCode = playCode.getIn(['PlayCode', 'QrCode'], null);
      var creationDate = new Date(Date.parse(playCode.getIn(['PlayCode', 'CreationDate'], null)));
      var expirationDate = new Date(Date.parse(creationDate));
      expirationDate.setDate(expirationDate.getDate() + 1);
      var timeLeft = expirationDate.getTime() - new Date().getTime();
      var isExpired = playCode.getIn(['PlayCode', 'IsExpired'], null); 
      var hasPlayedGames = playCode.getIn(['PlayCode', 'HasPlayedGames'], null); 
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "-" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "-" : game.getIn(['Game', 'GroupInstruction'], null);
      
      var themeName = "-";
      switch (theme) {
        case 1: {
          themeName = "Нефтопукльовците";
          break;
        }
        case 2: {
          themeName = "Прахопукльовците";
          break;
        }
        case 3: {
          themeName = "Бокльопукльовците";
          break;
        }
        case 4: {
          themeName = "Торопукльовците";
          break;
        }
        case 5: {
          themeName = "Лесопукльовците";
          break;
        }
        case 7: {
          themeName = "Прахопукитата";
          break;
        }
        case 6: {
          themeName = "Нефтопукитата";
          break;
        }
        case 8: {
          themeName = "Полицай с престъпници";
          break;
        }
        case 9: {
          themeName = "Екип реди пъзел";
          break;
        }
        case 10: {
          themeName = "Инцидент в океана";
          break;
        }
        case 11: {
          themeName = "Хранопукльовците";
          break;
        }
      } 
      return (
        <StyledPlayCode theme={process.theme}>
          
         <div id="playcode">          
          
          {!hasPlayCode &&
          <div>
            <div class="message">Нямате активен код за игра.</div>
            <div class="add">
              <FixedButton>
                <StandardButton href="/games" text="Разгледайте списъка с игрите" width="100%"/>
              </FixedButton>
            </div>
          </div>
          }
          {hasPlayCode && isExpired &&
            <div class="message">Кодът за игра е изтекъл.</div>
          }
          {gameNotLoaded && 
            <div class="message">Кодът се зарежда.</div>
          }
          {!gameNotLoaded && hasPlayCode && !isExpired &&
            <div>

              <div class="logo">
                <img src={"/images/print_logo.png"} alt="Лого" />
              </div>
              
              <div id="qrcode">
                <div class="scan">Сканирайте следния код на таблетите, за да стартирате играта:</div>
                <QRCode value={encryptedCode} size="500"/>
              </div>

              <div class="manualcode">
                <div class="enter">Или въведете ръчно следния код на таблетите:</div>
                <div class="manual"><b>{accessKey}</b></div>
              </div>

              <div class="details">
                <div class="detail noprint">
                  <div class="param">Оставащо активно време:</div>
                  <div class="value">{msToTime(timeLeft)}</div>
                </div>
                <div class="detail noprint">
                  <div class="param">Генериран на:</div>
                  <div class="value">{creationDate.toLocaleString()}</div>
                </div>
                <div class="detail">
                  <div class="param">Изтича на:</div>
                  <div class="value">{expirationDate.toLocaleString()}</div>
                </div>
                <div class="detail noprint">
                  <div class="param">Номер на играта:</div>
                  <div class="value"><Link to={'/game?id=' + gameId}>{gameId}</Link></div>
                </div>
                <div class="detail noprint">
                    <div class="param">{process.theme.subject} на играта:</div>
                    <div class="value">{subject}</div>
                </div>
                <div class="detail">
                    <div class="param">Заглавие на играта: </div>
                    <div class="value">{title}</div>
                </div>
                <div class="detail">
                    <div class="param">Кратко описание на играта: </div>
                    <div class="value">{description}</div>
                </div>                
                <div class="detail">
                    <div class="param">Инструкции за индивидуалните задачи: </div>
                    <div class="value">{individualInstruction}</div>
                </div>
                <div class="detail">
                    <div class="param">Инструкции за груповите задачи: </div>
                    <div class="value">{groupInstruction}</div>
                </div> 
                <div class="detail noprint">
                  <div class="param">Графична тема на играта:</div>
                  <div class="value">{themeName}</div>
                </div>
                <div class="detail">
                  <div class="param">Време за игра:</div>
                  <div class="value">{minutes} минути</div>
                </div>
                <div class="btns">
                  <div class="print">
                    <FixedButton>
                      <StandardButton click={this.print} text="Принтирайте кода" width="100%"/>
                    </FixedButton>
                  </div>
                  {isGoBack &&
                    <div class="back">
                      <FixedButton>
                        <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                      </FixedButton>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </StyledPlayCode>
    )
  }
}

PlayCode.propTypes = {
  isGoBack: PropTypes.bool,
  profile: PropTypes.object,
  playCode: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playCode: getPlayCode(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchResetPlayCode: () => dispatch(resetPlayCode()),
    fetchReadGame: (gameId) => dispatch(readGame(gameId)),
    fetchReadPlayCode: (id) => dispatch(readPlayCode(id)),
    fetchReadLastPlayCode: (profileId) => dispatch(readLastPlayCode(profileId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayCode)
