import React from 'react'
import Requirements from './requirements'
import Requirement from './requirement'

class OnlineRequirements extends React.Component {

  state = {
    expanded: false,
  }

  onToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
  let i18n = this.props.i18n;
  return (
        <Requirements i18n={i18n} title="Какви са техническите изисквания, за да играете с виртуалните игри на https://kod.puk.bg?">
          <Requirement attention="Препоръчителни са таблети (като на 1 таблет могат да играят до 6 човека).">Устройство със <b>сензорен екран</b> (touch screen), който е <b>поне 7 инча</b> (за да има достатъчно място 6 човека да се подредят в кръг около него).</Requirement>
          <Requirement>Достъп до <b>интернет</b>.</Requirement>
          <Requirement attention="Много е вероятно да срещнете проблеми, ако ползвате Internet Explorer.">Един от следните браузъри: <b><a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Mozilla Firefox</a>, Safari или Microsoft Edge</b>.</Requirement>
          <Requirement>Браузър, който поддържа технологията <b>WebGL</b> (проверете на <a href="https://get.webgl.org/" target="_blank">https://get.webgl.org/</a>).</Requirement>        
        </Requirements>
    )
  }
}
export default OnlineRequirements
