import { fromJS, Map } from 'immutable'


export function getPlayCode(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['playCode'], null)
}

export function setPlayCode(state, newState) {
  return state.merge(fromJS(newState.playCode))
}

export function resetPlayCode(state) {
  return new Map();
}

export function setPlayCodeError(state, error) {
  return state.merge({
    PlayCodeError: fromJS(error)
  })
}

export function getPlayCodes(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['playCodes'], null)
}

export function setPlayCodes(state, newState) {
  return state.merge(fromJS({playCodes: newState.playCodes}))
}

export function setPlayCodesError(state, error) {
  return state.merge({
    PlayCodesError: fromJS(error)
  })
}