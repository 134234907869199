import * as types from './types'

export function createProfile(firstName, secondName, email, password, activationCode) {
  return {
    type: types.CREATE_PROFILE_REQUEST,
    payload: {firstName, secondName, email, password, activationCode}
  }
}

export function readProfileGames(profileId) {
  return {
    type: types.READ_PROFILE_GAMES_REQUEST,
    payload: { profileId }
  }
}

export function updateProfile(profileId, firstName, secondName, email, password, activationCode) {
  return {
    type: types.UPDATE_PROFILE_REQUEST,
    payload: {profileId, firstName, secondName, email, password, activationCode}
  }
}

export function cancelProfileSubscription(profileId) {
  return {
    type: types.CANCEL_PROFILE_SUBSCRIPTION_REQUEST,
    payload: {profileId}
  }
}

export function reactivateProfileSubscription(profileId) {
  return {
    type: types.REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST,
    payload: {profileId}
  }
}

export function upgradeProfileSubscription(profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry,
  organizationTown, organizationAddress, organizationContactPerson, organizationNote) {
  return {
    type: types.UPGRADE_PROFILE_SUBSCRIPTION_REQUEST,
    payload: {profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry,
      organizationTown, organizationAddress, organizationContactPerson, organizationNote}
  }
}

export function repurchaseProfileSubscription(profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry,
  organizationTown, organizationAddress, organizationContactPerson, organizationNote) {
  return {
    type: types.REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST,
    payload: {profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry,
      organizationTown, organizationAddress, organizationContactPerson, organizationNote}
  }
}
