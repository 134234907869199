import React from 'react'
import StyledSubTitle from './subtitle.styled'

class GuideSubTitle extends React.Component {
  
  render() {
  
  let color = (this.props.color) ? this.props.color : "#f26173";

  return (
    <StyledSubTitle>
      <h2 class="title" style={{ color: color }}>{this.props.text}</h2>
    </StyledSubTitle>
    )
  }
}
export default GuideSubTitle
