import * as types from '../actions/types'
import initial from './initial'
import { setProfile, setError } from '../selectors/profile'

export default function (state = initial.profile, action) {
  switch (action.type) {
    case types.CREATE_PROFILE_RESPONSE:
    case types.READ_PROFILE_RESPONSE:
    case types.UPDATE_PROFILE_RESPONSE:
    case types.CANCEL_PROFILE_SUBSCRIPTION_RESPONSE:
    case types.REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE:
    case types.UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE:
    case types.REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE:
      return setProfile(state, action)
    case types.CREATE_PROFILE_ERROR:
    case types.READ_PROFILE_ERROR:
    case types.UPDATE_PROFILE_ERROR:
    case types.CANCEL_PROFILE_SUBSCRIPTION_ERROR:
    case types.REACTIVATE_PROFILE_SUBSCRIPTION_ERROR:
    case types.REPURCHASE_PROFILE_SUBSCRIPTION_ERROR:
    case types.UPGRADE_PROFILE_SUBSCRIPTION_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
