import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import StyledEditLevel from './level.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { deleteLevel, addLevel, updateLevel } from '../../../state/actions/game'

import Content from '../../ui/content'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from 'react-accessible-accordion';

var individualMaxlength = 550;
var groupMaxlength = 550;

export class EditLevel extends Component {
  
    constructor(props) {
      super()

      this.state = {
        isUpdate: false,
        isChanged: false,
        isDeleted: false,
        isAdded: false,
      }
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const id = target.id;
        this.setState({
            [ id ]: value,
            isChanged: true,
        });
    }

    submitDeleteExistingLevel = e => {
        e.preventDefault();
        let {level} = this.props;
        let levelId = level.getIn(['Id'], null);
        this.props.fetchDeleteLevel(levelId);
        this.props.updateLevel();
    }

    submitDeleteNewLevel = e => {
        e.preventDefault()
        this.props.deleteNewLevel(this.props.sequence);
    }

    clearChanges = () => {
        this.setState(state => ({
          isChanged: false
        }));
      }

    submitLevelDetails = e => {
        e.preventDefault()
        let {level} = this.props;
        let itId = `it-${this.props.id}`
        let gId = `g-${this.props.id}`
        if (!this.state.isUpdate) {
            this.props.fetchAddLevel(this.props.gameId, this.state[itId + "0"], this.state[itId + "1"], this.state[itId + "2"], this.state[itId + "3"], this.state[itId + "4"], this.state[itId + "5"], this.state[itId + "6"], this.state[itId + "7"], this.state[itId + "8"], this.state[itId + "9"], this.state[itId + "10"], this.state[itId + "11"], this.state[itId + "12"], this.state[itId + "13"], this.state[itId + "14"], this.state[itId + "15"], this.state[itId + "16"], this.state[itId + "17"], this.state[itId + "18"], this.state[itId + "19"], this.state[itId + "20"], this.state[itId + "21"], this.state[itId + "22"], this.state[itId + "23"], this.state[gId + "0"], this.state["p" + gId + "0"], this.state[gId + "1"], this.state["p" + gId + "1"], this.state[gId + "2"], this.state["p" + gId + "2"], this.state[gId + "3"], this.state["p" + gId + "3"], this.state[gId + "4"], this.state["p" + gId + "4"]);
            this.props.deleteNewLevel(this.props.sequence);
        } else {            
            let levelId = level.getIn(['Id'], null);
            let individualTexts = [];
            for (var i=0; i<this.state.numberOfIndividualTexts; i++) {
                individualTexts[i] = e.target[itId + i].value;
            }
            let groupAnswers = [];
            let groupPoints = [];
            for (var i=0; i<this.state.numberOfGroupAnswers; i++) {
                groupAnswers[i] = e.target[gId + i].value;
                groupPoints[i] = (e.target["p" + gId + i] != null) ? e.target["p" + gId + i].value : null;
            }
            this.props.fetchUpdateLevel(levelId, individualTexts[0], individualTexts[1], individualTexts[2], individualTexts[3], individualTexts[4], individualTexts[5], individualTexts[6], individualTexts[7], individualTexts[8], individualTexts[9], individualTexts[10], individualTexts[11], individualTexts[12], individualTexts[13], individualTexts[14], individualTexts[15], individualTexts[16], individualTexts[17], individualTexts[18], individualTexts[19], individualTexts[20], individualTexts[21], individualTexts[22], individualTexts[23], groupAnswers[0], groupPoints[0], groupAnswers[1], groupPoints[1], groupAnswers[2], groupPoints[2], groupAnswers[3], groupPoints[3], groupAnswers[4], groupPoints[4]);
            this.setState({
                isChanged: false,
                isDeleted: false,
                isAdded: false,
            });           
            this.props.updateLevel();
        }
    }

    render() {
        let {level, notOwned} = this.props;
        if (level != null && level != undefined) {
            this.state.isUpdate = true;
        }
        const groupQuestionAnswers = (this.state.isUpdate) ? level.getIn(['GroupQuestion','Answers'], []) : [];
        const individualTexts = (this.state.isUpdate) ? level.getIn(['IndividualQuestions','IndividualQuestion'], []) : [];
        let itId = `it-${this.props.id}`
        let gId = `g-${this.props.id}` 
        // INDIVIDUAL TEXTS
        const individualTextInputs = [];
        individualTexts.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx) => {
            if (!this.state.isChanged || idx < this.state.numberOfIndividualTexts) {
                    this.state.isDeleted = false;
                    const individualTextsAnswers = val.getIn(['Answers'], null);
                    individualTextsAnswers.map((val, idy) => {
                        const answerValue = val.getIn(['Value'], null);
                        individualTextInputs.push(
                            <div class="row"><input maxLength={individualMaxlength} {...notOwned ? {disabled:"true"} : {}} required {...(!this.state.isChanged ? {value: answerValue} : {})} placeholder={"Индивидуален текст " + (idx + 1) + "*"} type="text" name={itId + idx} data-id={this.props.id + idx} id={itId + idx} onChange={this.handleInputChange} /></div>); 
                    });
                } else {
                    this.state.isDeleted = true;
                    const individualTextsAnswers = val.getIn(['Answers'], null);
                    individualTextsAnswers.map((val, idy) => {
                        const answerValue = val.getIn(['Value'], null);
                        individualTextInputs.push(<div class="row deleted_answer">Индивидуален текст {(idx + 1)}: {answerValue}</div>);  
                    });
                }
            });
        if (!this.state.isChanged && individualTextInputs.length > 0) {
            this.state.numberOfIndividualTexts = individualTextInputs.length;
        }
        let individualTextsSize = individualTextInputs.length;
        this.state.isAdded = individualTextsSize < this.state.numberOfIndividualTexts;
        if (individualTextsSize < this.state.numberOfIndividualTexts) {
            for (var i=0; i < this.state.numberOfIndividualTexts - individualTextsSize; i++) {
                let idx = i + individualTextsSize;
                individualTextInputs.push(<div class="row"><input maxLength={individualMaxlength} {...notOwned ? {disabled:"true"} : {}} required placeholder={"Индивидуален текст " + (idx + 1) + "*"} type="text" name={itId + idx} data-id={this.props.id + idx} id={itId + idx} onChange={this.handleInputChange} /></div>);
            }
        }
        
        let expandedId = (this.state.isUpdate) ? -1 : this.props.id;
        // Group inputs
        const groupInputs = [];
        groupQuestionAnswers.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).sort((a, b) => b.getIn(['IsTrue'], null) - a.getIn(['IsTrue'], null)).map((val, idx)=> {
            const answerValue = val.getIn(['Value'], null);
            const answerPoints = val.getIn(['Points'], null);
            if (!this.state.isChanged) {
                groupInputs.push(<div class="row">
                    <div class="first"><input maxLength={groupMaxlength} {...(notOwned) ? {disabled:"true"} : {}} placeholder={"Oтговор " + (idx + 1) + "*"} required {...(!this.state.isChanged ? {value: answerValue} : {})} type="text" name={gId + idx} data-id={this.props.id + idx} id={gId + idx} onChange={this.handleInputChange} /></div>
                    <div class="second"><input {...notOwned ? {disabled:"true"} : {}} placeholder={"Точки за отговор " + (idx + 1) + "*"} required {...(!this.state.isChanged ? {value: answerPoints} : {})} type="text" name={"p" + gId + idx} data-id={this.props.id + idx} id={"p" + gId + idx} onChange={this.handleInputChange} /></div>
                </div>);
                
            } else if (idx < this.state.numberOfGroupAnswers) {
                groupInputs.push(<div class="row">
                    <div class="first"><input maxLength={groupMaxlength} {...(notOwned) ? {disabled:"true"} : {}} placeholder={"Oтговор " + (idx + 1) + "*"} required {...(!this.state.isChanged ? {value: answerValue} : {})} type="text" name={gId + idx} data-id={this.props.id + idx} id={gId + idx} onChange={this.handleInputChange} /></div>
                    <div class="second"><input {...notOwned ? {disabled:"true"} : {}} placeholder={"Точки за отговор " + (idx + 1) + "*"} required {...(!this.state.isChanged ? {value: answerPoints} : {})} type="text" name={"p" + gId + idx} data-id={this.props.id + idx} id={"p" + gId + idx} onChange={this.handleInputChange} /></div>
                </div>);
            } else {
                groupInputs.push(<div class="row deleted_answer">Oтговор {(idx + 1)}: {answerValue} {"(" + answerPoints + " точки )"}</div>);
            }
        })
        if (!this.state.isChanged && groupInputs.length > 0) {
            this.state.numberOfGroupAnswers = groupInputs.length;
        }
        let groupQuestionsSize = groupInputs.length;
        if (groupQuestionsSize < this.state.numberOfGroupAnswers) {
            for (var i=0; i < this.state.numberOfGroupAnswers - groupQuestionsSize; i++) {
                let idx = i + groupQuestionsSize;
                groupInputs.push(<div class="row">
                    <div class="first"><input maxLength={groupMaxlength} {...(notOwned) ? {disabled:"true"} : {}} placeholder={"Oтговор " + (idx + 1) + "*"} required type="text" name={gId + idx} data-id={this.props.id + idx} id={gId + idx} onChange={this.handleInputChange} /></div>
                    <div class="second"><input {...notOwned ? {disabled:"true"} : {}} placeholder={"Точки за отговор " + (idx + 1) + "*"} required type="text" name={"p" + gId + idx} data-id={this.props.id + idx} id={"p" + gId + idx} onChange={this.handleInputChange} /></div>
                </div>); 
            }
        }
        return (
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={[expandedId]}>
                <AccordionItem uuid={"l" + this.props.id}>
                    <StyledEditLevel>
                    <div class={(this.props.id % 2 == 0) ? "accordion__heading_1" : "accordion__heading_2"}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            {"Ниво " + (this.props.id + 1)}{(this.state.isChanged || this.state.isDeleted || this.state.isAdded) && "*"}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <Content>
                            <AccordionItemPanel>
                            <form onSubmit={this.submitLevelDetails}>
                                <div class="inputs">
                                    <div>
                                        <div class="level_inputs_title"><h4>Индивидуалнa задачa</h4></div>
                                        <div>
                                            <div class="row">
                                                <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: this.state.numberOfIndividualTexts} : {})} id='numberOfIndividualTexts' required onChange={this.handleInputChange}>
                                                    <option value="" disabled selected>Изберете броя на текстове, които ще бъдат разпределени между играчите*</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                </select>
                                            </div> 
                                            <div key={this.props.id}>
                                                {individualTextInputs}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="level_inputs_title"><h4>Групова задача</h4></div>
                                        <div>
                                            <div class="row">
                                                <select {...(notOwned) ? {disabled:"true"} : {}}  value={this.state.numberOfGroupAnswers} id='numberOfGroupAnswers' required onChange={this.handleInputChange}>
                                                    <option value="" disabled selected>Изберете броя на възможните отговори*</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>                     
                                            <div>
                                                {groupInputs}
                                            </div>
                                        </div>
                                    </div>
                                <div class='buttons'>
                                    <div class="btn_edit">
                                        <FixedButton>
                                            <StandardButton disabled={notOwned || (!this.state.isChanged && !this.state.isDeleted && !this.state.isAdded)} isSubmit text={(!this.state.isUpdate) ? "Създайте" : "Актуализирайте"} width="100%"/>
                                        </FixedButton>
                                    </div>
                                    <div class="btn_cancel">
                                        <FixedButton>
                                            <StandardButton disabled={notOwned || !this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                                        </FixedButton>
                                    </div>
                                    <div class="btn_delete">
                                        <FixedButton>
                                            <StandardButton disabled={notOwned} click={(this.state.isUpdate) ? this.submitDeleteExistingLevel : this.submitDeleteNewLevel} text="Изтрийте нивото" width="100%" type="delete"/>
                                        </FixedButton>
                                    </div>
                                </div>
                                </form>
                            </AccordionItemPanel>
                        </Content>
                    </div>
                    </StyledEditLevel>
                </AccordionItem>
            </Accordion>
        )
    }
}


EditLevel.propTypes = {
    gameId: PropTypes.string,
    id: PropTypes.string,
    sequence: PropTypes.string,
    deleteNewLevel: PropTypes.func,
    updateLevel: PropTypes.func,
    level: PropTypes.object,
    notOwned: PropTypes.bool,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeleteLevel: (levelId) => dispatch(deleteLevel(levelId)),
        fetchAddLevel: (gameId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5) => dispatch(addLevel(gameId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5)),
        fetchUpdateLevel: (levelId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5) => dispatch(updateLevel(levelId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditLevel)