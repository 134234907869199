import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import StyledRegister from './register.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'
import { createProfile} from '../../../state/actions/profile'

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

export class Register extends Component {

  state = {
    passwordsDoNotMatch: false,
  }

  constructor(props) {
    super();
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      [ id ]: value,
      passwordsDoNotMatch: false,
    });
  }

  submitForm = e => {
    e.preventDefault();

    if (this.state.password != this.state.confirmPassword) {
      // Password do not match
      this.setState({
        passwordsDoNotMatch: true,
      })
      return;
    }
    
    this.props.fetchCreateProfile(this.state.firstName, this.state.secondName, this.state.email, this.state.password, this.state.activationCode);
  }

  render() {
    const { profile, isCreateProfile } = this.props;
    var profileId = profile.getIn(['Profile', 'Id'], null);
    var errorCode = profile.getIn(['Error', 'Code'], null);
    var errorMessage = "";
    switch (errorCode) {
      case 11: 
        errorMessage = "Въвелите сте грешен код за активиране.";
        break;
      case 12:
        errorMessage = "Въведеният абонамент е неактивен.";
        break;
      case 51:
        errorMessage = "Вече съществува акаунт с въведения Email.";
        break;
      default:
        errorMessage = "Възникна грешка. Моля проверете въведените от Вас данни и опитайте отново.";
    }
    if (this.state.passwordsDoNotMatch) {
      errorCode = 0;
      errorMessage = "Въведените от Вас пароли не съвпадат.";
    }
    return (
      <StyledRegister>
        {profileId != null &&
          <Redirect to={'/'} />
        }
        <div id="register">
          <form onSubmit={this.submitForm}>
            <div class="inputs">
              <div>
                <h2>Въведете данни за акаунта:</h2> 
                <h4><b>ВНИМАНИЕ</b>: За да регистрирате акаунт в Пук.бг е нужно да имате код за активиране. Всяко училище, което използва Пук.бг разполага с такъв код и съответно можете да го получите от лицето, което отговаря за училищния абонамент. Ако Вашето училище все още не използва Пук.бг – можете да направите заявка като попълните следната <a href="https://puk.bg/bg/contact">форма</a> или като се обадите на 0893691583. Благодарим Ви за разбирането!</h4>
                <div class="row">
                  <input id='activationCode' type="password" required placeholder="Код за активиране*" onChange={this.handleInputChange}/>
                </div>
                
                <div class="row">
                  <input id='firstName' required placeholder="Име*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='secondName' required placeholder="Фамилия*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='email' required placeholder="Email*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='password' type="password" required placeholder="Парола*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='confirmPassword' type="password" required placeholder="Потвърдете паролата*" onChange={this.handleInputChange}/>
                </div>
              </div>              
            </div>
            <div>
              <div class='required'>* задължително поле</div>
              {errorCode != null &&
                <div class="error">
                  <div class="errorMessage">{errorMessage}</div>
                  {errorCode == 51 &&
                    <div class="recover"><Link to={"/forgottenPassword"}>Забравена парола?</Link></div>
                  } 
                </div>
              }
            </div>
            <div class='submit'>
              <FixedButton>
                <StandardButton isSubmit text="Регистрирайте се" width="100%"/>
              </FixedButton>
            </div>
          </form>             
      </div>
      </StyledRegister>
    )
  }
}

Register.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreateProfile: (firstName, secondName, email, password, activationCode) => dispatch(createProfile(firstName, secondName, email, password, activationCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
