import styled from 'styled-components'

const StyledLevel = styled.section`


@media print {
  [hidden] {
    display: block!important;
  }
}

.distribution {
  font-size: 0.5em;
  color: #000;
  font-weight: normal;
}

.level_inputs {
  padding-bottom: 30px;
}

.level_instruction {
  padding-bottom: 20px;
}

.level_inputs_title {
  font-size: 1.1em;
  color: ${props => props.theme.defaultColor};
  font-weight: bold;
  padding: 10px 0px;
}

.items_title {
  font-size: 1.1em;
  color: ${props => props.theme.defaultColor};
  font-weight: bold;
  padding: 20px 0px;
}

.item_detail {
  padding: 10px;
  display: block;
  width: 100%;
}

.item_color_1 {  
  border-bottom: 3px solid #FFF;
}

.item_color_1_top {
  border-top: 3px solid #FFF;
}

.item_color_2 {
  border-bottom: 3px solid #E8EBE2;
}

.item_color_2_top {
  border-top: 3px solid #E8EBE2;
}

.answer_detail, .list_detail {
  margin: 15px auto;
  text-align: center;

  @media (min-width: 1200px) {
    display: flex;
    width: 700px;
  }
}

.answer_title {
  padding-right: 20px;

  @media (min-width: 1200px) {
    text-align: right;
    width: 350px;
  }
}

.list_rank {
  font-weight: bold;
  text-align: right;
  width: 35px;
}

.list_value {
  padding: 0 10px;

  @media (min-width: 1200px) {
    text-align: left;
    width: 85%;
  }
}

.list_attr {
  text-align: center;
}

.item_detail_changes {
  font-size: 0.8em;
  font-weight:bold;
} 

.answer_value {    
  font-weight: bold;

  @media (min-width: 1200px) {
    width: 350px;
    text-align: center;
  }
}

.answer_points {    

  @media (min-width: 1200px) {
    width: 350px;
    text-align: left;
  }
}

.level_id {
  font-size: 0.7em;
  color: #000;
  font-weight: normal;
}

.positive_points, .reposition_correct {
  color: green;
}

.negative_points, .reposition_wrong {
  color: red;
}
}

.table {
  width: 80%;
  padding: 20px 0px 50px 0px;
  margin: 0 auto;
}

`

export default StyledLevel
