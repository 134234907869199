import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'

import DuplicateGame from '../../components/pages/edit/duplicate'

export class DuplicateGamePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Дублиране на игра"/>
        <DuplicateGame />
      </div>
    )
  }
}

DuplicateGamePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateGamePage)
