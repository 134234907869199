import { takeLatest } from 'redux-saga/effects'
import { createProfileSaga, readProfileSaga, readProfileGamesSaga, updateProfileSaga, cancelProfileSubscriptionSaga, reactivateProfileSubscriptionSaga, upgradeProfileSubscriptionSaga, repurchaseProfileSubscriptionSaga, forgottenPasswordSaga, createProfileSubscriptionSaga } from './sagas/profile'
import { createGameSaga, readGameSaga, readGameByAccessKeySaga, deleteGameSaga, updateGameSaga, addLevelSaga, updateLevelSaga, deleteLevelSaga, readGamesSaga, duplicateGameSaga } from './sagas/game'
import { createPlayCodeSaga, readPlayCodeSaga, readLastPlayCodeSaga, readPlayCodesSaga } from './sagas/playcode'

import * as types from './actions/types'

export function* watchCreateProfile() {
  yield takeLatest(types.CREATE_PROFILE_REQUEST, createProfileSaga)
}

export function* watchProfile() {
  yield takeLatest(types.READ_PROFILE_REQUEST, readProfileSaga)
}

export function* watchUpdateProfile() {
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfileSaga)
}

export function* watchCancelProfileSubscription() {
  yield takeLatest(types.CANCEL_PROFILE_SUBSCRIPTION_REQUEST, cancelProfileSubscriptionSaga)
}

export function* watchReactivateProfileSubscription() {
  yield takeLatest(types.REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST, reactivateProfileSubscriptionSaga)
}

export function* watchUpgradeProfileSubscription() {
  yield takeLatest(types.UPGRADE_PROFILE_SUBSCRIPTION_REQUEST, upgradeProfileSubscriptionSaga)
}

export function* watchRepurchaseProfileSubscription() {
  yield takeLatest(types.REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST, repurchaseProfileSubscriptionSaga)
}

export function* watchCreateGame() {
  yield takeLatest(types.CREATE_GAME_REQUEST, createGameSaga)
}

export function* watchProfileGames() {
  yield takeLatest(types.READ_PROFILE_GAMES_REQUEST, readProfileGamesSaga)
}

export function* watchReadGame() {
  yield takeLatest(types.READ_GAME_REQUEST, readGameSaga)
}

export function* watchReadGameByAccessKey() {
  yield takeLatest(types.READ_GAME_BY_ACCESS_KEY_REQUEST, readGameByAccessKeySaga)
}

export function* watchDeleteGame() {
  yield takeLatest(types.DELETE_GAME_REQUEST, deleteGameSaga)
}

export function* watchDuplicateGame() {
  yield takeLatest(types.DUPLICATE_GAME_REQUEST, duplicateGameSaga)
}


export function* watchUpdateGame() {
  yield takeLatest(types.UPDATE_GAME_REQUEST, updateGameSaga)
}

export function* watchAddLevel() {
  yield takeLatest(types.ADD_LEVEL_REQUEST, addLevelSaga)
}

export function* watchUpdateLevel() {
  yield takeLatest(types.UPDATE_LEVEL_REQUEST, updateLevelSaga)
}

export function* watchDeleteLevel() {
  yield takeLatest(types.DELETE_LEVEL_REQUEST, deleteLevelSaga)
}

export function* watchReadGames() {
  yield takeLatest(types.READ_GAMES_REQUEST, readGamesSaga)
}

export function* watchForgottenPassword() {
  yield takeLatest(types.FORGOTTEN_PASSWORD_REQUEST, forgottenPasswordSaga)
}

export function* watchCreatePlayCode() {
  yield takeLatest(types.CREATE_PLAYCODE_REQUEST, createPlayCodeSaga)
}

export function* watchReadPlayCode() {
  yield takeLatest(types.READ_PLAYCODE_REQUEST, readPlayCodeSaga)
}

export function* watchReadPlayCodes() {
  yield takeLatest(types.READ_PLAYCODES_REQUEST, readPlayCodesSaga)
}

export function* watchReadLastPlayCode() {
  yield takeLatest(types.READ_LAST_PLAYCODE_REQUEST, readLastPlayCodeSaga)
}

export function* watchCreateProfileSubscription() {
  yield takeLatest(types.CREATE_PROFILE_SUBSCRIPTION_REQUEST, createProfileSubscriptionSaga)
}

