
import styled from 'styled-components'

const StyledContent = styled.section`

@media (min-width: 1200px) {
  .content {
    width: 1200px;
    margin: 0 auto;
  }
}

`
export default StyledContent