import styled from 'styled-components'

const StyledGame = styled.section`

.nogame {
  font-size: 1.2em;
  text-align: center;
  padding: 70px;
}

#gamedetails {
  font-size: 1.20em;
  padding-bottom: 50px;
  
  #info {
    padding: 70px 70px 0 70px;
    text-align: center;

    .detail {
      margin: 15px auto;

      @media (min-width: 1200px) {
        display: flex;
        width: 1200px;
      }
    }

    .title {
      font-weight: bold;
      color: ${props => props.theme.defaultColor};

      @media (min-width: 1200px) {
        text-align: right;
        padding-right: 20px;
        width: 400px;
      }
    }

    .value {
      @media (min-width: 1200px) {
        text-align: left;
        width: 800px;
      }
    }

    .free .value {
      font-weight: bold;
      color: #3a953c;
    }
  }

  .demo {
    padding-bottom: 30px;
  }

  .buttons {
    padding-top: 50px;
  }

  .generate {
    padding-bottom: 0px;
  }

  .duplicate {
    padding-bottom: 4px;
  }
}

.resetinggame {
  font-size: 1.2em;
  text-align: center;
  padding: 100px;
}

.levelstitle, .parameterstitle {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: bold;
}

.levelstitle h4, .parameterstitle h4 {
  font-size: 1.7em;
}

.demo {
  margin-top: 50px;
}

.parameterstable {
  width: 60%;
  margin: 0 auto;
}

.theme {
  width: 90%;
}

.subscribe {
  margin-bottom: 5px;
}

.invite {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 20px;
}
`

export default StyledGame
