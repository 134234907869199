import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { updateProfile } from '../../../state/actions/profile'
import {  getProfile } from '../../../state/selectors/profile'

export class Profile extends Component {
  constructor(props) {
    super()
    this.state = {
      isUpdate: false,
      isChanged: false,
      passwordsDoNotMatch: false
    }
    this.startUpdate = this.startUpdate.bind(this);
  }

  startUpdate = () => {
    this.setState(state => ({
      isUpdate: true
    }));
  }

  stopUpdate = () => {
    this.setState(state => ({
      isUpdate: false,
      passwordsDoNotMatch: false,
    }));
  }

  clearChanges = () => {
    this.setState(state => ({
      isChanged: false,
      passwordsDoNotMatch: false,
    }));
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    
   this.setState({
      [ id ]: value,
      isChanged: true,
      passwordsDoNotMatch: false,
    });
  }

  submitProfileDetails = e => {
    e.preventDefault();
    
    if (this.state.password != this.state.confirmPassword) {
      // Password do not match
      this.setState({
        passwordsDoNotMatch: true,
      })
      return;
    }

    const { profile } = this.props
    const profilеId = profile.getIn(['Profile', 'Id'], null);
    let email = profile.getIn(['Profile', 'Email'], null);
    if (this.state.email) {
      email = this.state.email;
    }
    this.props.fetchUpdateProfile(profilеId, this.state.firstName, this.state.secondName, email, this.state.organization, this.state.password, null, null, null, null, null, null, null, null);
    
    this.setState(state => ({
      isUpdate: false,
      isChanged: false,
      organization: undefined,
      name: undefined,
      email: undefined,
      phone: undefined
    }));

  }

  render() {

      const { profile } = this.props
      const firstName = profile.getIn(['Profile', 'FirstName'], null);
      const secondName = profile.getIn(['Profile', 'SecondName'], null);
      const email = profile.getIn(['Profile', 'Email'], null);
      const organization = profile.getIn(['Profile', 'Organization'], null);

      return (
        <StyledProfile theme={process.theme}>
         <div id="profile">
          <div>

            {!this.state.isUpdate &&
            <div>
              <div class="details_section">
                <div class="detail_item">
                    <div class="detail_attribute">Име: </div>
                    <div class="detail_value">{firstName} {secondName}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Email: </div>
                    <div class="detail_value"><a href={"mailto:" + email}>{email}</a></div>
                </div>
                {organization &&
                <div class="detail_item">
                    <div class="detail_attribute">Организация: </div>
                    <div class="detail_value">{organization}</div>
                </div>
                }
              </div>
              <div class="detail_button">
                <FixedButton>
                  <StandardButton click={this.startUpdate} text="Актуализирайте" width="100%"/>
                </FixedButton>
              </div>
            </div>
            }

            {this.state.isUpdate &&
              <form onSubmit={this.submitProfileDetails}>
                <h2>Моля въведете детайли на акаунта си:</h2>
                <div class="inputs">
                  <div class="row">
                    <input {...(!this.state.isChanged ? {value: firstName} : {})} id='firstName' required placeholder="Първо име*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input {...(!this.state.isChanged ? {value: secondName} : {})} id='secondName' required placeholder="Фамилно име*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input {...(!this.state.isChanged ? {value: email} : {})} id='email' required placeholder="Email*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input {...(!this.state.isChanged ? {value: organization} : {})} id='organization' placeholder="Организация" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input id='password' type="password" placeholder="Парола*" required onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input id='confirmPassword' type="password" required placeholder="Потвърдете паролата*" onChange={this.handleInputChange}/>
                  </div> 
                  {this.state.passwordsDoNotMatch &&
                    <div class="error">
                      <div class="errorMessage">Въведените от Вас пароли не съвпадат.</div>
                    </div>
                  }
                  <div class="buttons">
                      <div class="btn_edit">
                        <FixedButton>
                          <StandardButton disabled={this.state.isUpdate && !this.state.isChanged} isSubmit text="Актуализирайте" width="100%"/>
                        </FixedButton>
                      </div>
                      {this.state.isUpdate &&
                        <div class="btn_cancel">
                          <FixedButton>
                            <StandardButton disabled={!this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                          </FixedButton>
                        </div>
                      }                  
                    <div class="btn_back">
                        <FixedButton>
                          <StandardButton click={this.stopUpdate} text="Откажете актуализацията" width="100%" type="back"/>
                        </FixedButton>
                      </div>
                  </div>
                </div>              
              </form>
              }
          </div>
        </div>
      </StyledProfile>
    )
  }
}

Profile.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchUpdateProfile: (profilеId, firstName, secondName, email, organization, password, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote) => dispatch(updateProfile(profilеId, firstName, secondName, email, organization, password, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
