import styled from 'styled-components'

const StyledPhysicalGame = styled.div`
.codes img {
  width: 800px;
}

.teams {
  text-align: center;
  padding: 50px 0px;
}

.appreq {
  margin-top: 40px;
}

.onlinereq {
  margin-top: 20px;
}

.tablets, .start {
  text-align: center;
  padding-top: 50px;
}

.p20_1 {
  position: absolute;
  left: 135px;
  top: 40px;
}

.p20_2 {
  position: absolute;
  left: 755px;
  top: 400px;
}

.p21_1 {
  position: absolute;
  left: 545px;
  top: 200px;
}

.p21_2 {
  position: absolute;
  left: 525px;
  top: 400px;
}

.p1_1 {
  position: absolute;
  left: 5px;
  top: 40px;
}

.p1_2 {
  position: absolute;
  left: 5px;
  top: 100px;
}

.p1_3 {
  position: absolute;
  left: 20px;
  top: 200px;
}

.p1_4 {
  position: absolute;
  left: 70px;
  top: 400px;
}

.p2_1_1 {
  position: absolute;
  left: 170px;
  top: 250px;
}

.p2_1 {
  position: absolute;
  left: 350px;
  top: 100px;
}

.p2_2 {
  position: absolute;
  left: 700px;
  top: 0px;
}

.p2_3 {
  position: absolute;
  left: 780px;
  top: 70px;
}

.p2_4 {
  position: absolute;
  left: 620px;
  top: 75px;
}

.p2_5 {
  position: absolute;
  left: 780px;
  top: 420px;
}

.p2_6 {
  position: absolute;
  left: 660px;
  top: 420px;
}

.p2_7 {
  position: absolute;
  left: 100px;
  top: 70px;
}

.p2_8 {
  position: absolute;
  left: 200px;
  top: 410px;
}

.p2_9 {
  position: absolute;
  left: 120px;
  top: 490px;
}

.p2_10 {
  position: absolute;
  left: 30px;
  top: 410px;
}

.p3_1 {
  position: absolute;
  left: 280px;
  top: 150px;
}

.p3_2 {
  position: absolute;
  left: 430px;
  top: 270px;
}

.p3_3 {
  position: absolute;
  left: 770px;
  top: 70px;
}

.p4_1 {
  position: absolute;
  left: 180px;
  top: 390px;
}

.p4_2 {
  position: absolute;
  left: 150px;
  top: 300px;
}

.p4_3 {
  position: absolute;
  left: 430px;
  top: 180px;
}

.p4_4 {
  position: absolute;
  left: 660px;
  top: 320px;
}

.p4_5 {
  position: absolute;
  left: 720px;
  top: 410px;
}

.p5_1 {
  position: absolute;
  left: 380px;
  top: 180px;
}

.p5_2 {
  position: absolute;
  left: 180px;
  top: 320px;
}

.p5_3 {
  position: absolute;
  left: 720px;
  top: 400px;
}

.p6_1 {
  position: absolute;
  left: 350px;
  top: 250px;
}

.p7_1 {
  position: absolute;
  left: 200px;
  top: 380px;
}

.p8_1 {
  position: absolute;
  left: 350px;
  top: 210px;
}

.p8_2 {
  position: absolute;
  left: 520px;
  top: 330px;
}

.p8_3 {
  position: absolute;
  left: 150px;
  top: 200px;
}

.p8_4 {
  position: absolute;
  left: 780px;
  top: 420px;
}

.p8_5 {
  position: absolute;
  left: 650px;
  top: 20px;
}

.p8_6 {
  position: absolute;
  left: 790px;
  top: 20px;
}

.p10_1 {
  position: absolute;
  left: 180px;
  top: 220px;
}

.p10_2 {
  position: absolute;
  left: 750px;
  top: 320px;
}

.p10_3 {
  position: absolute;
  left: 500px;
  top: 420px;
}

.p30_1 {
  position: absolute;
  left: 540px;
  top: 190px;
}

.p30_2 {
  position: absolute;
  left: 540px;
  top: 290px;
}
  
`

export default StyledPhysicalGame
