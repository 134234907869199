import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import StyledDeleteGame from './delete.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'

import { deleteGame } from '../../../state/actions/game'

import queryString from 'query-string'
import history from '../../../history'

export class DeleteGame extends Component {
  
  constructor(props) {
    super()

    const values = queryString.parse(window.location.search);
    var gameId = "";
    if (values.gameId != null && values.gameId != "") {
      gameId = values.gameId;
    } else if (values.gameid != null && values.gameid != "") {
      gameId = values.gameid;
    } else if (values.id != null && values.id != "") {
      gameId = values.id;
    }
    this.state = {
      gameId: gameId,
      isDeleted: false,
    }

    this.deleteGame = this.deleteGame.bind(this);
  }

  deleteGame = e => {
    e.preventDefault()    
    this.props.fetchDeleteGame(this.state.gameId)
    this.setState({
      isDeleted: true,
    })
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      return (
      <StyledDeleteGame>
        {this.state.isDeleted &&
            <Redirect to="/mygames" />
        }
        {!this.state.isDeleted &&
          <div class="delete">
            <div class="question">Сигурни ли сте, че искате да изтриете игра <Link to={'/game?id=' + this.state.gameId}>{this.state.gameId}</Link>?</div>
            <div class="btns">
              <div class="yes">
                <FixedButton>
                  <StandardButton click={this.deleteGame} text="Да" width="100%" />
                </FixedButton>
              </div>
              <div class="no">
                <FixedButton>
                  <StandardButton href={'/editgame?id=' + this.state.gameId} text="Не" width="100%" type="delete"/>
                </FixedButton>
              </div>
              <div class="back">
                <FixedButton>
                  <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                </FixedButton>
              </div>
            </div>
          </div>
        }
      </StyledDeleteGame>
    )
  }
}

DeleteGame.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeleteGame: (gameId) => dispatch(deleteGame(gameId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGame)
