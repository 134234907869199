
import styled from 'styled-components'

const StyledFixedButton = styled.section`

.fixedbutton {
  width: 90%;
  margin: 0 auto 0 auto;
}

@media (min-width: 1200px) {
  .fixedbutton {
    width: 300px;
  }
}

`
export default StyledFixedButton