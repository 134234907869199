import styled from 'styled-components'

const StyledFooter = styled.section`

  @media print {
    display: none!important;
  }

  background-color: ${props => props.theme.footerBgrColor};
  color: #fff;
    
  #footer {
    padding: 0px 0px 25px 0px;
    width: 100%;
    background-color: ${props => props.theme.footerBgrColor};
    text-align: center;

    @media print {
      display: none;
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      justify-content: center;      
      padding: 25px 0px;
    }

    .item {
      padding: 15px 0px;
      border-bottom: 1px solid #fff;      

      @media (min-width: 768px) {
        border-bottom: none;
        width: 100%;
      }

      a {
        padding: 20px;
        color: #FFF;
        text-decoration: none;
        vertical-align: middle;
        :hover {
          opacity: 0.7;
        }
      }
    }
  }
  
  #legal {
    display: flex;
    justify-content: center;
    padding: 0 7px 14px 7px;

    @media print {
      display: none;
    }
        
    @media (min-width: 768px) {
      padding: 0 14px 21px 14px;
    }
  
    div {
      width: 100%;
      font-size: .77em;
      color: #FFF;
      text-align: center;
      
      @media (min-width: 768px) {
        max-width: 500px;
      }
    }
  }
`

export default StyledFooter