
import styled from 'styled-components'

const StyledSubTitle = styled.section`

.title {
  font-size: 1.60em;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
  padding-top: 0px;
  margin: 0px;
  text-align: center;

  @media print {
    display:none;
  }
}

@media (min-width: 1200px) {

  .title {
    font-size: 2.250em;
  }
  
}
`
export default StyledSubTitle