import styled from 'styled-components'

const StyledRegister = styled.div`
  
  h4 {
    font-size: 1.2em;
    text-align: justify;
    background-color: #F26173;
    color: #fff;
    padding: 50px;
    border-radius: 25px;
    margin-bottom: 40px;
  }

  h4 a {
    color: #000;
  }

  #register {
    margin-bottom: 0px;
  }

  .subtitle {
    margin-top: 40px;
  }

  form {
    margin-bottom: 50px;
  }

  form .inputs {
    width: 80%;
  }


  a {
    font-size: 1.1em;
  }

  .links {
    text-align: center;
  }

  .recover a {
    color: #f26173;
    font-size: 1em;
    padding-top: 20px;
  }

  .invoice {
    margin-top: 20px;
  }

  .errorMessage {
    font-size: 1.5em;
  }
  
`

export default StyledRegister
