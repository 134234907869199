import styled from 'styled-components'

const StyledInstallGame = styled.div`

.latest {
    font-size: 3.5em;
    font-weight: bold;
    text-align: center;
}

.text {
    text-align: center;
    font-size:1.3em;
}

.download {
    margin-bottom: 30px;
}

.download a {
    width: 90% !important;
    margin: 0 auto;
}

@media (min-width: 1200px) {
    .download a {
        width: 550px !important;
        margin: 0 auto;
    }
}
`

export default StyledInstallGame
