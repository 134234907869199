import React, { Component } from 'react'
import { connect } from 'react-redux'
import Guide from '../../components/pages/guides/guide'
import Tournament from '../../components/pages/guides/sections/tournament'

export class TournamentGuidePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <Guide title="Ръководство за участие в турнир" version="Версия 23.2">
        <Tournament/>
      </Guide>      
    )
  }
}

TournamentGuidePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamentGuidePage)
