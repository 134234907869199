import styled from 'styled-components'

const StyledButton = styled.section`

.button button {
  border-radius: 50px;
  border: none;
  padding: 14px 14px;
  cursor: pointer;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: ${props => props.theme.font};

  @media (min-width: 1200px) {   
    font-family: ${props => props.theme.fontBold}; 
    font-weight: bold;
    font-size: 18px;
  }
}

.button a {
  border-radius: 50px;
  border: none;
  padding: 14px 14px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  display: block;
  text-align: center;
  font-family: ${props => props.theme.font};
  
  @media (min-width: 1200px) {    
    font-family: ${props => props.theme.fontBold};
    font-weight: bold;
    font-size: 18px;
  }
}

.button button:disabled {
  background-color: rgba(100, 100, 100, 0.3);
}

.link {
  padding: 5px 0px !important;
}

`
export default StyledButton