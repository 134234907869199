import React from 'react'
import StyledGuide from '../guide.styled'
import StyledPreparations from './preparations.styled'
import Section from '../../../ui/section'
import HubRequirements from './requirements/hub'
import GuideSubTitle from '../subtitle'
import Content from '../../../ui/content'
import Video from '../video'

class Preparations extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <StyledPreparations>
        <Section bgr="#E8EBE2">
          <Content>
            <GuideSubTitle text="Преди часа"/>
            {/** <Video id="https://youtu.be/Ba331XWJkgY"/> */}
            <h3>Стъпка 1: ВЛЕЗТЕ В ПРОФИЛА СИ</h3>
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">{process.theme.url}</div>
                <img class="screen" src={"/images/guides/preparations/screen1.png"} alt="Екран за вход"/>
                <div class={["podlojka", "p1_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p1_2"].join(' ')}><div>2</div></div>
                <div class={["podlojka", "p1_3"].join(' ')}><div>3</div></div>
              </div>
              <div class="left">    
                <h4>1. Отворете <a href={process.theme.url} target="_blank">{process.theme.url}</a></h4>
                <div>
                  <HubRequirements />
                </div>
                <h4>2. Въведете данните за Вашия профил</h4>
                <div>Въведете Вашите email и парола. </div>
                <div><span class="info">Инфо:</span>В случай, че не помните своята парола – може да я възстановите като натиснете линка <a href={process.theme.url + "/recover"} target="_blank">„Възтановете паролата си?“</a>. След като попълните формата – ние ще Ви изпратим паролата на Вашия email.</div>
                <div><span class="attention">Важно:</span>Ако нямате профил – може да регистрирате такъв като натиснете линка <a href={process.theme.url + "/register"} target="_blank">„Регистрирайте се?“</a>. За целта ще са Ви нужни абонаментен номер и код за активиране, които ние сме Ви изпратили, когато сте закупили абонамент.</div>
                <h4>3. Натиснете „Влез“</h4>
                <div><span class="info">Инфо:</span>Щом веднъж влезете в своя профил – браузърът Ви вече автоматично ще въвежда Вашите email и парола при отваряне на <a href={process.theme.url} target="_blank">{process.theme.url}</a>.</div>
              </div>
            </div>

            <h3>Стъпка 2: НАТИСНЕТЕ „РАЗГЛЕДАЙТЕ СПИСЪКА С ИГРИТЕ“</h3>
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">{process.theme.url}</div>
                <img class="screen" src={"/images/guides/preparations/screen2.png"} alt="Начален екран"/>
                <div class={["podlojka", "p2_1"].join(' ')}><div>1</div></div>
              </div>
              <div class="left">    
                <h4>1. Натиснете „Разгледайте списъка с игрите“</h4>
              </div>
            </div>

            <h3>Стъпка 3: ИЗБЕРЕТЕ ВЪЗРАСТ</h3>
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">{process.theme.url}/games</div>
                <img class="screen" src={"/images/guides/preparations/screen3.png"} alt="Изберете игра"/>
                <div class={["podlojka", "p31_1"].join(' ')}><div>1</div></div>
              </div>
              <div class="left">    
                <h4>1. Изберете възраст</h4>
                <div>Така ще видите само игрите, които са подходящи за съответната възраст.</div>
              </div>
            </div>

            <h3>Стъпка 4: ИЗБЕРЕТЕ ИГРАТА, КОЯТО ЩЕ ИГРАЕТЕ</h3>
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">{process.theme.url}/games</div>
                <img class="screen" src={"/images/guides/preparations/screen4.png"} alt="Изберете игра"/>
                <div class={["podlojka", "p3_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p3_2"].join(' ')}><div>2</div></div>
                <div class={["podlojka", "p3_3"].join(' ')}><div>3</div></div>
              </div>
              <div class="left">    
                <h4>1. Филтрирайте игрите</h4>
                <div>Филтрирайте игрите, за да може по-бързо да намерите подходящата за Вас игра.</div>
                <h4>2. Изберете играта, която ще играете</h4>
                <div><span class="info">Инфо:</span>Преди да генерирате кода за играта – може да я разгледате в подробности (и да достъпите нейна симулация) като натиснете „Разгледайте“.</div>
                <h4>3. Натиснете „Генерирайте код за игра“</h4>
              </div>
            </div>

            <div>
              <h3>Стъпка 5: ВЪВЕДЕТЕ ПАРАМЕТРИТЕ НА ИГРАТА</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                  <div class="url">{process.theme.url}/createplaycode?gameId=1009</div>
                  <img class="screen" src={"/images/guides/preparations/screen5.png"} alt="Дефиниране на параметрите на играта"/>
                  <div class={["podlojka", "p4_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p4_2"].join(' ')}><div>2</div></div>
                  <div class={["podlojka", "p4_4"].join(' ')}><div>3</div></div>
                </div>
                <div class="left">
                  <h4>1. Определете времето за игра</h4>
                  <h4>2. Изберете темата, с която ще играете</h4>
                  <div>Темата задава графичния облик на играта.</div>
                 <h4>3. Натиснете „Генерирайте код за игра“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 6: ПРЕГЛЕДАЙТЕ КОДА ЗА ИГРА</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                  <div class="url">{process.theme.url}/playcode?id=1696</div>
                  <img class="screen" src={"/images/guides/preparations/screen6.png"} alt="Прегледайте кода за играта"/>
                  <div class={["podlojka", "p5_2"].join(' ')}><div>2</div></div>
                  <div class={["podlojka", "p5_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. QR код</h4>
                  <div>Този QR код съдържа информация за параметрите на играта. Той следва да се сканира от устройството, на което ще играете.</div>
                  <h4>2. Ръчен код за игра</h4>
                  <div>Ръчният код за игра е алтернатива на QR кода. В случай че не може да сканирате кода от устройствата, на които ще играете (напр. защото камерата не работи) – имате възможност да въведете кода ръчно (вместо да го сканирате).</div>
                  <div><span class="attention">Важно:</span> Кодът е валиден 24 часа след неговото генериране.</div>
                  
                  </div>
              </div>
            </div>
          </Content>
        </Section>
      </StyledPreparations>
    </StyledGuide>
    )
  }
}
export default Preparations
