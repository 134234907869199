import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledLoginForm from './form.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

export class LoginForm extends Component {

  state = {
    profileEmail: localStorage.getItem('profileEmail'),
    profilePassword: localStorage.getItem('profilePassword')
  }

  constructor(props) {
    super();
    if (this.state.profileEmail != null && this.state.profileEmail != "") {
      var pwd = (this.state.profilePassword != null && this.state.profilePassword.length > 0) ? this.state.profilePassword : "";
      props.submitLogin(
        this.state.profileEmail,
        pwd
      )
    }
    
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      [ id ]: value
    });
  }


  submitLogin = e => {
    e.preventDefault()
    this.props.submitLogin(
      this.state.profileEmail,
      this.state.profilePassword
    )
  }
  
  render() {
    return (
      <StyledLoginForm>
        <div id="login">
          <form onSubmit={this.submitLogin}>
            <h2>Влезте във вашия акаунт:</h2>
            <div class="inputs">
              <div class="row">
                <input tabIndex={1} id='profileEmail' value={this.state.profileEmail} required placeholder="Email*" onChange={this.handleInputChange}/>
              </div>
              <div class="row">
                <input tabIndex={2} type='password' value={this.state.profilePassword} id='profilePassword' placeholder="Парола" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div class='required'>* задължително поле</div>
            <div class='submit'>
            <FixedButton>
              <StandardButton isSubmit text="Влезте" width="100%"/>
            </FixedButton>
            </div>
            {(!this.props.isAuthenticated && this.props.isApiError) &&
            <div className='error'>Нещо се обърка. Извинете ни за неудобството. Опитайте отново по-късно.</div>}
            {(!this.props.isAuthenticated && this.props.isAuthError) &&
            <div className='error'>Грешен email или парола.</div>}
          </form>
          <div class="links">
            {this.state.formSelected == 1 && <div><a href="/recover">Възстановете паролата си?</a></div>}
            {this.state.formSelected == 1 && <div><a href="/register">Регистрирайте се?</a></div>}
          </div> 
      </div>
      </StyledLoginForm>
    )
  }
}

LoginForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthError: PropTypes.bool,
  isApiError: PropTypes.bool,
  submitLogin: PropTypes.func,
}

export default LoginForm
