import styled from 'styled-components'

const StyledTheme = styled.section`
.theme {
  padding-top: 5px;
  width: 100%;
  border-radius: 45px;
}

`
export default StyledTheme