import React from 'react'
import Content from '../../ui/content'
import StyledTitle from './title.styled'

class GuideTitle extends React.Component {
  
  render() {

  let bgr = (this.props.bgr) ? this.props.bgr : "#009EC0";
  let color = (this.props.color) ? this.props.color : "#fff";

  return (
    <StyledTitle>
      <div class="guidetitle" style={{ background: bgr, color: color}}>
        <Content>
          <h1 class="title">{this.props.title}</h1>
          <div class="version">{this.props.version}</div>
        </Content>
      </div>
    </StyledTitle>
    )
  }
}
export default GuideTitle
