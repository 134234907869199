import React from 'react'
import StyledGuide from '../guide.styled'
import StyledPhysical from './tournament.styled'
import Section from '../../../ui/section'
import GuideSubTitle from '../subtitle'
import Content from '../../../ui/content'
import AppRequirements from './requirements/app'
import OnlineRequirements from './requirements/online'
import Video from '../video'

class Tournament extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <StyledPhysical>
        <Section bgr="#FFF">
          <GuideSubTitle text="Преди началото на турнира"/>
          <Content>
            <h3>Стъпка 1: ИЗБЕРЕТЕ ТУРНИР, В КОЙТО ДА СЕ ВКЛЮЧИТЕ</h3>          
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://caringers.com/bg/tournament/register</div>
                <img class="screen" src={"/images/guides/tournament/screen1.png"} alt="Избор на турнир"/>
                <div class={["podlojka", "p20_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p20_2"].join(' ')}><div>2</div></div>
              </div>
              <div class="left">    
                <h4>1. Отворете <a href="https://caringers.com/bg/tournament/register" target="_blank">https://caringers.com/bg/tournament/register</a></h4>
                <h4>2. Изберете турнир</h4>
              </div>
            </div>

            <h3>Стъпка 2: РЕГИСТРИРАЙТЕ СЕ ЗА ТУРНИРА*</h3> 
            <div class="asterix">*за всеки отбор, с който ще участвате в турнира, е необходима отделна регистрация</div>
            <div class="section1">
              <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://caringers.com/bg/tournament/register</div>
                <img class="screen" src={"/images/guides/tournament/screen2.png"} alt="Избор на турнир"/>
                <div class={["podlojka", "p21_1"].join(' ')}><div>1</div></div>
                <div class={["podlojka", "p21_2"].join(' ')}><div>2</div></div>
              </div>
              <div class="left">    
                <h4>1. Попълнете формата</h4>
                <h4>2. Натиснете „Запишете отбора“</h4>
                <div><span class="attention">Важно:</span><b>До 3 работни дни</b> трябва да получите потвърждение на имейла, който сте посочили. <b>В него ще намерите кода за достъп до турнира за съответния отбор</b>.</div>
              </div>
            </div>

            <h3>Стъпка 3: ЗАПАЗЕТЕ КОДА ЗА УЧАСТИЕ В ТУРНИРА</h3> 
            <div class="asterix"><span class="attention">Важно:</span> Запазете кода, който ще получите след завършване на регистрацията. С него ще стартирате играта щом турнирът започне.</div>

            <h3>Стъпка 4: УВЕРЕТЕ СЕ, ЧЕ ИМАТЕ ТАБЛЕТИ С ИНСТАЛИРАНО ПРИЛОЖЕНИЕТО НА ПУК.БГ</h3> 
            <div class="asterix"><span class="attention">Важно:</span> За да може да участвате в турнира е необходимо да имате поне един таблет, на който да се случи играта. Също така, на него трябва да е инсталирано приложението на Пук.бг (вижте как да направите това на <a href='https://kod.puk.bg/install' target='_blank'>https://kod.puk.bg/install</a>).</div>
            <div class="appreq"><AppRequirements /></div>
          </Content>
        </Section>
        <Section bgr="#E8EBE2">
          <Content>
              <GuideSubTitle text="По време на турнира"/>
              <h3>Стъпка 1: СТАРТИРАЙТЕ ПРИЛОЖЕНИЕТО ПУК.БГ НА ТАБЛЕТА</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen2_1.jpg" alt="Сканиращ екран"/>
                  <div class={["podlojka", "p2_1_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Приложението Пук.бг</h4>
                  <div>Открийте и стартирайте приложението Пук.бг на таблета.</div>
                </div>
              </div>

              <h3>Стъпка 2: НАТИСНЕТЕ БУТОНА РЪЧЕН КОД</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen2.jpg" alt="Сканиращ екран"/>
                  <div class={["podlojka", "p2_10"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „Ръчен код“ </h4>
                </div>
              </div>

              <h3>Стъпка 3: ВЪВЕДЕТЕ РЪЧНИЯ КОД НА ОТБОРА, КОЙТО СТЕ ПОЛУЧИЛИ ПРИ РЕГИСТРАЦИЯ</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/tournament/screen3.jpg" alt="Сканиращ екран"/>
                  <div class={["podlojka", "p30_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p30_2"].join(' ')}><div>2</div></div>
                </div>
                <div class="left">    
                  <h4>1. Въведете кода, който сте получили при регистрация за турнира</h4>
                  <div><span class="attention">Важно:</span>Всеки отбор, с който участвате в турнира, трябва да има индивидуален код за достъп до него.</div>
                  <h4>2. Натиснете „Готово“</h4>
                  <div><span class="info">Инфо:</span>Кодът може да бъде използван само веднъж и е валиден само по време на протичане на турнира.</div>
                </div>
              </div>
              
              <h3>Стъпка 4: ИЗБОР НА БРОЯ НА ИГРАЧИТЕ В ОТБОРА</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen3.jpg" alt="Начален екран"/>
                  <div class={["podlojka", "p3_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p3_2"].join(' ')}><div>2</div></div>
                </div>
                <div class="left">    
                  <h4>1. Бутони от 1 до 6</h4>
                  <div>Определят големината на отбора – може да се състой от най-много 6 ученици.</div>
                  <h4>2. Старт</h4>
                  <div>Поканете отборът да избере своята големина и им дайте знак, когато да започнат играта като натиснат този бутон.</div>
                </div>
              </div>

              <h3>Стъпка 5: НАСЪРЧЕТЕ ИГРАЧИТЕ ДА ОЦЕНЯТ ЧУВСТВАТА СИ В НАЧАЛОТО НА ИГРАТА</h3> 
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen4.jpg" alt="Оценка на емоциите"/>
                  <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Оценка на емоциите на всеки играч</h4>
                  <div>Дава възможност на всеки играч да оцени как се чувства в началото на играта. Жълтото усмихнато лице означава, че ученикът се чувства добре, а синьото натъжено лице – че не се чувства добре (напр. е тъжен, гневен, разочарован и др.).</div>
                </div>
              </div>

              <h3>*Стъпка 6: ОСТАВЕТЕ ИГРАЧИТЕ ДА СЕ ЗАПОЗНАЯТ С ИНСТРУКЦИИТЕ НА ИГРАТА</h3> 
              <div class="asterix">*в случай, че играта има инструкции преди играта</div>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen10.jpg" alt="Инструкции за играта"/>
                  <div class={["podlojka", "p10_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p10_2"].join(' ')}><div>2</div></div>
                  <div class={["podlojka", "p10_3"].join(' ')}><div>3</div></div>
                </div>
                <div class="left">    
                  <h4>1. Инструкции</h4>
                  <div>Някои игри имат специфични инструкции, с които играчите трябва да се запознаят.</div>
                  <h4>2. Скрол бар</h4>
                  <div>Скрол барът Ви позволява да достъпите целите инструкции тогава, когато те са твърде много, за да се съберат на екрана.</div>
                  <h4>3. Готово</h4>
                  <div>Отборът следва да натисне готово, когато се запознае с инструкциите.</div>
                </div>
              </div>

              <h3>Стъпка 7: ИГРА</h3>
              <div class="start">Всяка игра включва текстове, които са разпределени между играчите и задача, която те трябва да решат заедно, като екип. Когато това се случи - играчите трябва да посочат един от отговорите, разположене в средата на игралното поле.</div>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen5.jpg" alt="Игрови екран"/>
                  <div class={["podlojka", "p4_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p4_2"].join(' ')}><div>2</div></div>
                  <div class={["podlojka", "p4_3"].join(' ')}><div>3</div></div>
                  <div class={["podlojka", "p4_4"].join(' ')}><div>4</div></div>
                  <div class={["podlojka", "p4_5"].join(' ')}><div>5</div></div>
                </div>
                <div class="left">    
                  <h4>1. Текстове, разпределни на случаен принцип между играчите</h4>
                  <h4>2. Подложка с допълнителни инструкции, свързани с текстовете</h4>
                  <h4>3. Възможни отговори</h4>
                  <div><span class="info">Инфо:</span>Играта изисква от играчите да си сътрудничат и да работят в екип, за да октрият отговорът на задачата в нивото, който обикновено е следствие от текстовете, разпределени между играчите.</div>
                  <h4>4.Подложка с допълнителни инструкции, свързани с възможните отговори</h4>
                  <h4>5. Оставащо време до края на играта</h4>
                </div>
              </div>

              <h3>Стъпка 8: НАСЪРЧЕТЕ УЧЕНИЦИТЕ ДА ОЦЕНЯТ ЧУВСТВАТА СИ В КРАЯ НА ИГРАТА</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen7.jpg" alt="Оценка на емоциите"/>
                  <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Оценка на емоциите на всеки играч</h4>
                  <div>Дава възможност на всеки играч да оцени как се чувства в края на играта. Жълтото усмихнато лице означава, че ученикът се чувства добре, а синьото натъжено лице – че не се чувства добре (напр. е тъжен, гневен, разочарован и др.).</div>
                </div>
              </div>

              <h3>Стъпка 9: РЕЗУЛТАТ ОТ ИГРАТА</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/physicalgame/screen8.jpg" alt="Екран с резултата от играта"/>
                  <div class={["podlojka", "p8_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p8_2"].join(' ')}><div>2</div></div>
                  <div class={["podlojka", "p8_3"].join(' ')}><div>3</div></div>
                  <div class={["podlojka", "p8_5"].join(' ')}><div>4</div></div>
                  <div class={["podlojka", "p8_6"].join(' ')}><div>5</div></div>
                </div>
                <div class="left">    
                  <h4>1. Резултат</h4>
                  <div>Показва резултата на отбора. </div>
                  <div><span class="info">Инфо:</span> Максималният резултат е 100 и означава, че учениците са решили без грешка всички въпроси.</div>
                  <h4>2. Показател за настроението в екипа</h4>
                  <div>Показва какво е настроението в екипа – как са се чувствали играчите в края на играта. Колкото повече са червените сърца, толкова повече положителни емоции са преобладавали в екипа по време на играта.</div>
                  <div><span class="info">Инфо:</span> Настроението на екипа се измерва на базата на индивидуалните оценки на чувствата на играчите в края на играта.</div>
                  <h4>3. Нови Пукльовци</h4>
                  <div>След пукането на Пукльото – той се размножава и се появяват нови Пукльовци, които продължават неговата мисия по опазване на околната среда!</div>
                  <h4>4. Затварете приложението Пук.бг</h4>
                  <div>Натиснете този бутон, за да затворите игра.</div>
                </div>
              </div>
          </Content>
        </Section>
        <Section bgr="#FFF">
          <GuideSubTitle text="След края на турнира"/>
          <Content>
            <h3>Стъпка 1: ОЧАКВАЙТЕ ИМЕЙЛ С РЕЗУЛТАТИТЕ ОТ ТУРНИРА</h3>
            <h3>Стъпка 2: ПОЛУЧЕТЕ СВОЯ СЕРТИФИКАТ ЗА УЧАСТИЕ В ТУРНИРА</h3>
          </Content>
        </Section>
      </StyledPhysical>
    </StyledGuide>
    )
  }
}
export default Tournament
