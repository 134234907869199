import React from 'react'
import GuideTitle from './title'
import StyledGuide from './guide.styled'


class Guide extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <section class="guide">
        <GuideTitle title={this.props.title} version={this.props.version}/>
        <div class="details">
          {this.props.children}
        </div>
      </section>
    </StyledGuide>
    )
  }
}
export default Guide
