import * as types from './types'

export function createGame(profileId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction) {
  return {
    type: types.CREATE_GAME_REQUEST,
    payload: { profileId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction}
  }
}

export function updateGame(gameId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction) {
  return {
    type: types.UPDATE_GAME_REQUEST,
    payload: { gameId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction}
  }
}

export function readGame(gameId) {
  return {
    type: types.READ_GAME_REQUEST,
    payload: { gameId }
  }
}

export function readGameByAccessKey(accessKey) {
  return {
    type: types.READ_GAME_BY_ACCESS_KEY_REQUEST,
    payload: { accessKey }
  }
}

export function deleteGame(gameId) {
  return {
    type: types.DELETE_GAME_REQUEST,
    payload: { gameId }
  }
}

export function duplicateGame(gameId) {
  return {
    type: types.DUPLICATE_GAME_REQUEST,
    payload: { gameId }
  }
}

export function resetGame() {
  return {
    type: types.RESET_GAME
  }
}

export function addLevel(gameId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5) {
  return {
    type: types.ADD_LEVEL_REQUEST,
    payload: { gameId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5 }
  }
}

export function updateLevel(levelId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5) {
  return {
    type: types.UPDATE_LEVEL_REQUEST,
    payload: { levelId, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5 }
  }
}

export function deleteLevel(levelId) {
  return {
    type: types.DELETE_LEVEL_REQUEST,
    payload: { levelId }
  }
}

export function readGames() {
  return {
    type: types.READ_GAMES_REQUEST
  }
}