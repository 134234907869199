import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import StyledNavigation from './navigation.styled.js'
import storage from '../../state/storage'
import { logOut } from '../../state/actions/login'

import { getProfile } from '../../state/selectors/profile'

export class LoginNavigation extends Component {
  state = {
    menuOpen: false,
  }

  closeMenu = (e) => {
    if (e.target.id == "logout") {
      this.submitLogout();
    } else {
      e.preventDefault();
      this.setState({ menuOpen: false })
    }
  }

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen })
  }

  submitLogout() {
    storage().dispatch(logOut());
  }

  render() {
    var { profile } = this.props;
    const canPublish = profile.getIn(['Profile', 'Email'], false) == "stavros.stavru@caringers.com";
    return (
       <StyledNavigation theme={process.theme}>
         <div id="menu-desktop">
          <ul>            
            <li><Link to="/">Моите кодове за игра</Link></li>
            <li><Link to="/games">Списък с игри</Link></li>
            {canPublish &&
              <li><Link to="/mygames">Моите игри</Link></li>
            }
            <li><Link to={"/guide"}>Ръководство за игра</Link></li>
            <li><Link to={"/tournament"}>Ръководство за турнир</Link></li>
            <li><Link to="/install">Инсталация на игрите</Link></li>
            <li><a id="logout" href="/" onClick={this.submitLogout}>Изход</a></li>
          </ul>
        </div>
        <div id="menu-mobile" onClick={this.closeMenu}>
          <Menu width={'100%'} isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}>
            <Link to="/">Моите кодове за игра</Link>
            <Link to="/games">Всички игри</Link>
            {canPublish && 
              <Link to="/mygames">Моите игри</Link>
            }
            <Link to="/profile">Моят акаунт</Link>
            <Link to={"/guide"}>Ръководство за игра</Link>
            <Link to="/tournament">Ръководство за турнир</Link>
            <Link to="/install">Инсталация на игрите</Link>
            <a id="logout" href="">Изход</a>
          </Menu>
        </div>
        </StyledNavigation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

LoginNavigation.propTyes = {
  profile: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginNavigation)