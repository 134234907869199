import styled from 'styled-components'

const StyledPhysicalGame = styled.div`

.p1_1 {
  position: absolute;
  left: 450px;
  top: 380px;
}

.p2_1 {
  position: absolute;
  left: 150px;
  top: 80px;
}

.p3_1 {
  position: absolute;
  left: 670px;
  top: 140px;
}

.p3_2 {
  position: absolute;
  left: 280px;
  top: 420px;
}

.p4_1 {
  position: absolute;
  left: 310px;
  top: 140px;
}

.p4_2 {
  position: absolute;
  left: 260px;
  top: 390px;
}

.p5_1 {
  position: absolute;
  left: 620px;
  top: 290px;
}

.p6_1 {
  position: absolute;
  left: 240px;
  top: 140px;
}

.p7_1 {
  position: absolute;
  left: 560px;
  top: 140px;
}

.p8_1 {
  position: absolute;
  left: 540px;
  top: 290px;
}

.p9_1 {
  position: absolute;
  left: 700px;
  top: 140px;
}

.p10_1 {
  position: absolute;
  left: 650px;
  top: 400px;
}

.p11_1 {
  position: absolute;
  left: 480px;
  top: 190px;
}

.p12_1 {
  position: absolute;
  left: 280px;
  top: 410px;
}

.p13_1 {
  position: absolute;
  left: 170px;
  top: 230px;
}

  
`

export default StyledPhysicalGame
