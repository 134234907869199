import styled from 'styled-components'

const StyledEditGamePage = styled.section`
#playcode {
 text-align: center;
}

.dashboard {
    margin-bottom: 50px;
}

@media print {
    .logo {
        display: block !important;
        padding-bottom: 50px;
    }

    .logo img {
        width: 200px;
    }

    .noprint {
        display: none !important;
    }
}

.logo {
    display: none;
}

canvas {
    width: 250px;
    height: 250px;

    @media print {
        width: 40%;
        height: 40%;
    }

    @media (min-width: 1200px) {
        width: 300px;
        height: 300px;
    }
}

.details {
    font-size: 1.2em;
}

.detail {
    padding-top: 20px;

    @media print {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: top;
    }
    
    @media (min-width: 1200px) {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: top;
    }
}

.param {
    color: ${props => props.theme.defaultColor};
    
    @media (min-width: 1200px) {
        width: 400px;
        text-align: right;
    }

    @media print {
        width: 400px;
        text-align: right;
    }
}

.value {
    padding-left: 10px;
    
    @media (min-width: 1200px) {
        width: 400px;
        text-align: left;
    }

    @media print {
        width: 400px;
        text-align: left;
    }
}

#qrcode {
    margin-bottom: 30px;
}

#manualcode {
    margin: 0px 0 30px 0;
}

.scan {
    font-size: 1.4em;
    padding-bottom: 40px;
    color: #F26173;
}

.enter {
    font-size: 1.4em;
    padding-bottom: 20px;
    color: #F26173;
}

.manual {
    font-size: 3em;
}

.addTeam {
    font-size: 0.8em;
    margin-bottom: 40px;
}

.addbtns {
    margin-top: 20px;
}

.addbtns button {
    margin-top: 10px;
}

.add {
    margin-top: 50px;
}

.results {
    margin-top: 20px;
}

.name {
    font-size: 1.5em;
}

.name span {
    font-weight: bold;
}

.playerbtns {
    margin-top: 20px;
    padding-bottom: 40px; 
}

.delete {
    margin-top: 10px;
}

.btns {
    padding-top: 40px;
    @media print {
        display: none;
    }
}

.print {
    padding-top: 10px;
    @media print {
        display: none;
    }
}

.back {
    padding-top: 10px;
    @media print {
        display: none;
    }
}

.copyPlayers {
    margin-top: 40px;
}

.addPlayer {
    margin-top: 10px;
}

.addPlayer form div {
    margin: 0 2px;
}

.addPlayer form input {
    width: 200px;
    font-size: 0.8em;
} 

.addPlayer form select {
    width: 200px;
    font-size: 0.8em;
} 

.addPlayer form .inputs {
    width: 100%;
} 

.players button {
    margin: 0 auto;
    width: 200px !important;
} 

.players {
    padding: 20px 0px;
    width: 80%;
    margin: 0 auto;
}

.resultdetails {
    padding-bottom: 40px;
}

.desc {
    font-size: 0.8em;
    font-style: italic;
}
`

export default StyledEditGamePage
