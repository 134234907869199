import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import StyledFooter from './footer.styled'

export class Footer extends Component {
  render() {
    return (
        <StyledFooter theme={process.theme}>
          <section id="footer">
            <div>
              <div class="item">Email: {process.theme.email}</div>
              <div class="item">Телефон: {process.theme.phone}</div>
              <div class="item"><a href={process.theme.contactURL} target="_blank">Свържете се с нас</a></div>
            </div>
          </section>
          <section id="legal">
            <div>Всички права запазени. Попалс ООД {new Date().getFullYear()}.</div>
          </section>
      </StyledFooter>
    )
  }
}

export default Footer
