import styled from 'styled-components'

const StyledPhysicalGame = styled.div`

.p1_1 {
  position: absolute;
  left: 130px;
  top: 45px;
}

.p2_1 {
  position: absolute;
  left: 690px;
  top: 70px;
}

.p3_1 {
  position: absolute;
  left: 260px;
  top: 190px;
}

.p3_2 {
  position: absolute;
  left: 360px;
  top: 400px;
}

.p4_1 {
  position: absolute;
  left: 360px;
  top: 400px;
}

.p5_1 {
  position: absolute;
  left: 150px;
  top: 400px;
}

.p6_1 {
  position: absolute;
  left: 320px;
  top: 180px;
}

.p7_1 {
  position: absolute;
  left: 420px;
  top: 380px;
}

.p8_1 {
  position: absolute;
  left: 430px;
  top: 230px;
}

.p8_2 {
  position: absolute;
  left: 470px;
  top: 350px;
}

.p9_1 {
  position: absolute;
  left: 515px;
  top: 340px;
}

.p11_1 {
  position: absolute;
  left: 480px;
  top: 350px;
}

.p12_1 {
  position: absolute;
  left: 100px;
  top: 180px;
}
  
`

export default StyledPhysicalGame
