import * as types from './types'


export function logIn(email, password) {
  return { 
    type: types.READ_PROFILE_REQUEST,
    payload: { email, password }
  }
}

export function logOut() {
  return {
    type: types.AUTH_LOGOUT
  }
}