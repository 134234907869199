import { fromJS, Map } from 'immutable'


export function getGame(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['game'], null)
}

export function setGame(state, newState) {
  return state.merge(fromJS(newState.game))
}

export function resetGame(state) {
  return new Map();
}

export function setError(state, error) {
  return state.merge({
    Error: fromJS(error)
  })
}

export function getGames(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['games'], null)
}

export function setGames(state, newState) {
  return state.merge(fromJS({games: newState.games}))
}

