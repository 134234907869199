import React from 'react'
import StyledTablet from './tablet.styled'

class Tablet extends React.Component {
  
  render() {
  return (
    <StyledTablet>
      <div>
        <div class="container">
          <div class="content">
            {this.props.children}
          </div>
        </div>
      </div>
    </StyledTablet>
    )
  }
}
export default Tablet
