import React, { Component } from 'react'
import { connect } from 'react-redux'
import Guide from '../../components/pages/guides/guide'
import Preparations from '../../components/pages/guides/sections/preparations'
import PhysicalGame from '../../components/pages/guides/sections/physicalgame'

export class PhysicalGuidePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <Guide title="Ръководство за игра" version="Версия 23.2">
        <Preparations isPhysical={true}/>
        <PhysicalGame isFree={false} isWeb={false}/>
      </Guide>      
    )
  }
}

PhysicalGuidePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalGuidePage)
