import React from 'react'
import InstallGuide from './install.styled'
import Android from './android'
import Windows from './windows'
import StandardSection from '../../../ui/section'
import SectionTitle from '../../../ui/subtitle'
import Content from '../../../ui/content'
import FixedButton from '../../../ui/fixedbutton'
import StandardButton from '../../../ui/button'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';


import axios from 'axios'

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};


class Install extends React.Component { 

  state = {
    isLoaded: false,
    version: "-",
  }

  constructor(props) {
    super()
    this.getVersion = this.getVersion.bind(this);
    this.versionReceived = this.versionReceived.bind(this); 
  }

  componentDidMount() {
    this.getVersion();
  }

  getVersion = () => {
    const API_ENDPOINT = `${process.env.REACT_APP_SERVER}/version/games`
    axios.get(API_ENDPOINT, { headers })
      .then(response => {
        this.versionReceived(response.data);
      })
  }

  versionReceived = (jsonData) => {
    this.setState(
      {
        isLoaded: true,
        version: jsonData.Version.LatestVersion
      }
    )
  }

  render() {
  let i18n = this.props.i18n;
  return (
    <InstallGuide>
      {this.state.isLoaded &&
      <StandardSection bgr="#E8EBE2">
        <div class="version">
          <div class="latest">{this.state.version}</div>
          <div class="text">последна версия</div>
        </div>
      </StandardSection>
      }
      <StandardSection bgr="#fff">
        <SectionTitle text="Android OS"/>
        <div class="download">
          <StandardButton isLink isDownload href="/packages/pukbg.apk" text="Свалете инсталационния пакет" width="100%" />
        </div>
        <div>
          <Accordion allowZeroExpanded allowMultipleExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Инструкции за инсталация на Android OS
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Android />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </StandardSection>
      {/**
      <StandardSection bgr="#E8EBE2">
        <SectionTitle text="Microsoft Windows"/>
        <div class="download">
          <StandardButton isLink isDownload href="/packages/pukbg_86.exe" text="Свалете инсталационния пакет (32-битов / x86)" width="100%" />
        </div>
        <div class="download">
          <StandardButton isLink isDownload href="/packages/pukbg_86_64.exe" text="Свалете инсталационния пакет (64-битов / x64)" width="100%" />
        </div>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Инструкции за инсталация на Microsoft Windows
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Windows />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </StandardSection>
      */}
    </InstallGuide>
    )
  }
}
export default Install
