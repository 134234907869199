import React from 'react'
import StyledGuide from '../guide.styled'
import StyledPhysical from './windows.styled'
import Section from '../../../ui/section'
import GuideSubTitle from '../subtitle'
import Content from '../../../ui/content'

class Windows extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <StyledPhysical>
          <Content>
            
            <div>
              <h3>Стъпка 1: ОТВОРЕТЕ KOD.PUK.BG</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg</div>
                  <img class="screen" src="/images/guides/install/win/screen1.png" alt="Отворете kod.puk.bg"/>
                  <div class={["podlojka", "p1_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Отворете адрес <a href="https://kod.puk.bg" target="_blank">kod.puk.bg</a></h4>
                </div>
              </div>
            </div>
            
            <div>
              <h3>Стъпка 2: ИЗБЕРЕТЕ „ИНСТАЛАЦИЯ НА ИГРИТЕ“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg</div>
                  <img class="screen" src="/images/guides/install/win/screen2.png" alt="Изберете от менюто"/>
                  <div class={["podlojka", "p2_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Изберете „Инсталационни на игрите“ от менюто</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 3: НАТИСНЕТЕ „СВАЛЕТЕ ИНСТАЛАЦИОННИЯ ПАКЕТ“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen3.png" alt="Свалете инсталационния пакет"/>
                  <div class={["podlojka", "p3_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Натиснете „Свалете инсталационния пакет“</h4>
                  <div><span class="attention">Важно:</span>Ще трябва да разберете дали вашият компютър работи с 32-битова (x86) или 64-битова (x64) версия на Microsoft Windows. В зависимост от това трябва да изберете съответния инсталационен пакет.</div>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 4: НАТИСНЕТЕ ИЗТЕГЛЕНИЯ ИНСТАЛАЦИОНЕН ПАКЕТ</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen4.png" alt="Натиснете изтегления инсталационен пакет"/>
                  <div class={["podlojka", "p5_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Натиснете върху изтегления инсталационен пакет</h4>
                  <div><span class="attention">Важно:</span>Браузърът Ви може да поиска изрично съгласие от Вас, за да свали файла. За целта ще е нужно да натиснете „Keep“ / „Запази“.</div>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 5: НАТИСНЕТЕ „MORE INFO“ / „ДОПЪЛНИТЕЛНА ИНФОРМАЦИЯ“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen5.png" alt="Натиснете допълнителна информация"/>
                  <div class={["podlojka", "p6_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Натиснете върху „More info“ / „Допълнителна информация“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 6: НАТИСНЕТЕ „RUN ANYWAY“ / „СТАРТИРАЙТЕ ИНСТАЛАЦИЯТА“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen6.png" alt="Стартирайте инсталацията"/>
                  <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Натиснете върху „Run anyway“ / „Стартирайте инсталацията“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 7: НАТИСНЕТЕ „NEXT“ / „НАПРЕД“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen7.png" alt="Продължете напред"/>
                  <div class={["podlojka", "p8_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p8_2"].join(' ')}><div>2</div></div>
                </div>
                <div class="left">
                  <h4>1. Отметнете, за да имате икона на Пук.бг на началния си екран</h4>
                  <h4>2. Натиснете „Next“ / „Напред“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 8: НАТИСНЕТЕ „INSTALL“ / „ИНСТАЛИРАЙTE“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen8.png" alt="Продължете с инсталацията"/>
                  <div class={["podlojka", "p9_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>2. Натиснете „Install“ / „Инсталирайте“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 9: ИЗЧАКАЙТЕ ИНСТАЛАЦИЯТА ДА ПРИКЛЮЧИ</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen9.png" alt="Изчакайте"/>
                  <div class={["podlojka", "p10_1"].join(' ')}><div>1</div></div>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 10: НАТИСНЕТЕ „FINISH“ / „ГОТОВО“</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen10.png" alt="Натиснете готово"/>
                  <div class={["podlojka", "p11_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1. Натиснете „Finish“ / „Готово“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 11: ОТВОРЕТЕ PUK.BG, ЗА ДА СЕ УВЕРИТЕ, ЧЕ ВСИЧКО Е НАРЕД</h3>
              <div class="section1">
                <div class={["right", "monitor"].join(' ')}>
                <div class="url">https://kod.puk.bg/install</div>
                  <img class="screen" src="/images/guides/install/win/screen11.png" alt="Отворете приложението"/>
                  <div class={["podlojka", "p12_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">
                  <h4>1.  Натиснете иконата на Пук.бг, за да стартирате приложението.</h4>
                </div>
              </div>
            </div>
            
          </Content>
      </StyledPhysical>
    </StyledGuide>
    )
  }
}
export default Windows
