import styled from 'styled-components'

const StyledTablet = styled.section`
.tablet {
  background-color: #E8EBE2;
  padding: 70px 0;
}

@media (min-width: 1200px) {
  .container {
    padding-top: 38px;
    padding-right: 50px;
    padding-left: 50px;
    margin: 0 auto;
    position: relative;
    background-image: url("/images/tablet.png");
    background-repeat: no-repeat;
    background-size: 1135px 600px;
    background-position: left top;
    width: 1135px;
    height: 600px;
    display: flex;
    padding-top: 54px;
    padding-bottom: 25px;
    padding-left: 89px;
    -webkit-box-shadow: -8px 8px 0px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: -8px 8px 0px 0px rgba(0,0,0,0.13);
    box-shadow: -8px 8px 0px 0px rgba(0,0,0,0.13);
    border-radius: 25px;
  }

  .content {
    display: block;
    width: 965px;
  }
  
}
`
export default StyledTablet
