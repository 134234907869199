import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import history from '../../../history'
import { Link } from 'react-router-dom'

import StyledFormPlayCode from './form.styled'
import StyledPlayCode from './playcode.styled'
import {  getProfile } from '../../../state/selectors/profile'
import {  getPlayCode } from '../../../state/selectors/playcode'
import {  getGame } from '../../../state/selectors/game'

import { createPlayCode, resetPlayCode } from '../../../state/actions/playcode'

import { readGame, resetGame } from '../../../state/actions/game'

import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import Theme from '../../ui/theme'

import queryString from 'query-string'

export class FormPlayCode extends Component {

  state = {
    isFetching: false,
  }

  constructor(props) {
    super()
    
    props.fetchResetPlayCode();
    props.fetchResetGame();
    
    const values = queryString.parse(window.location.search);
    if (values.gameId != null && values.gameId != "") {
      this.gameId = values.gameId;
    } else if (values.gameid != null && values.gameid != "") {
      this.gameId = values.gameid;
    } else {
      this.gameId = "";
    }

    if (this.gameId != null && this.gameId != "") {
      this.state = {
        gameId: this.gameId,
      }
      props.fetchReadGame(this.gameId);
    } else {
      console.log("Error!");
    }
  }

  handleconferencePositionChange = (event) => {
    this.setState({
      conferencePosition: event.target.value,
    });
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    this.setState({
      [ id ]: value,
    });
  }
  
  submitPlayCode = e => {
    e.preventDefault()
    const { profile } = this.props
    const profileId = profile.getIn(['Profile', 'Id'], null);
    // params
    var params = "";
    Object.keys(this.state).map( (n)=> {
      if (n.indexOf("param_") >= 0) {
        params += n.replace("param_", "") + ":" + this.state[n] + "#";
      }
    });
    this.props.fetchCreatePlayCode(this.state.subscriptionId, profileId, this.state.gameId, this.state.theme, this.state.minutes, params); 
    this.setState({
      isFetching: true,
    });
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      const { profile, playCode, game } = this.props;
      const subscription = profile.getIn(['Profile', 'Subscription'], null);
      var isActive = (subscription) ? subscription.getIn(["IsActive"]) : false;
      this.state.subscriptionId = (subscription) ? subscription.getIn(["Id"]) : null;
      var isFree = (game) ? game.getIn(['Game', 'IsFree'], false) : false;
      if (game.length > 0 && !(isActive || isFree)) {
        return;
      }
      var id = playCode.getIn(['PlayCode', 'Id'], null);
      var errorCode = playCode.getIn(['Error', 'Code'], null);
      var errorMessage = "";
      switch(errorCode) {
        case 42:
          errorMessage = "Достигнат е лимита на избрания абонамент. Моля изберете друг абонамент.";
          break;
        case 10:
          errorMessage = "Посоченият от Вас абонамент не е активен. Моля изберете друг абонамент.";
          break;
        case 50:
          errorMessage = "Използваният от Вас акаунт е неактивен.";
          break;
        default:
          errorMessage = "Възникна грешка. Моля опитайте отново или се обадете на 0893691583.";
      }
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? "" : game.getIn(['Game', 'Levels', 'Level'], null);
      let theme = (game.getIn(['Game', 'Theme'], null) == null) ? "" : game.getIn(['Game', 'Theme'], null);
      if (theme != "") {
        this.state.theme = theme;
      }
      const gameId = (game.getIn(['Game', 'Id'], null) == null) ? "" : game.getIn(['Game', 'Id'], null);
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "-" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "-" : game.getIn(['Game', 'GroupInstruction'], null);
      return (
        <StyledFormPlayCode>
          {this.state.isFetching && id != null &&
            <Redirect to={'/playcode?id=' + id} />
          }
         <div id="playcode">
            <StyledPlayCode theme={process.theme}>
            <div class="details">
              <div class="detail">
                  <div class="param">Номер на играта:</div>
                  <div class="value"><Link to={'/game?id=' + gameId}>{gameId}</Link></div>
                </div>
                <div class="detail">
                    <div class="param">{process.theme.subject} на играта:</div>
                    <div class="value">{subject}</div>
                </div>
                <div class="detail">
                    <div class="param">Заглавие на играта: </div>
                    <div class="value">{title}</div>
                </div>
                <div class="detail">
                    <div class="param">Кратко описание на играта: </div>
                    <div class="value">{description}</div>
                </div>                
                <div class="detail">
                    <div class="param">Инструкции по подразбиране за индивидуалните задачи: </div>
                    <div class="value">{individualInstruction}</div>
                </div>
                <div class="detail">
                    <div class="param">Инструкции по подразбиране за груповите задачи: </div>
                    <div class="value">{groupInstruction}</div>
                </div> 
            </div>
            </StyledPlayCode>
            {!(isActive || isFree) &&
              <div class="message">Не можете да генерирате код за тази игра.</div>
            }
            {(isActive || isFree) &&
              <form onSubmit={this.submitPlayCode}>
                <h2>Моля въведете следните детайли:</h2>
                <div class="inputs">
                  <div class="row">
                  <select id='minutes' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Време за игра*</option>
                    <option value="5">5 минути</option>
                    <option value="10">10 минути</option>
                    <option value="15">15 минути</option>
                    <option value="20">20 минути</option>
                    <option value="25">25 минути</option>
                    <option value="30">30 минути</option>
                  </select>
                  </div>
                  {theme == "" &&
                  <div>
                    <div class="row">
                      <select id='theme' {...(theme != "" ? {value: theme} : {})} {...(theme != "" ? {disabled: true} : {})} required onChange={this.handleInputChange}>
                        <option value="-1" selected disabled>Тема на играта*</option>
                        {process.theme.themes}
                      </select>
                    </div>
                    {this.state.theme && this.state.theme != "" && this.state.theme != "0" && this.state.theme.length > 0 &&
                    <div class="row">
                      <Theme id={this.state.theme} />
                    </div>
                    }
                  </div>
                  }
                  
                </div>
                <div class='required'>* задължително поле</div>
                {errorCode > null && 
                  <div class="error">
                    {errorMessage}
                  </div>
                }
                <div class='submit'>
                  <FixedButton>
                    <StandardButton isSubmit text="Генерирайте код за игра" width="100%"/>
                  </FixedButton>
                </div>
                <div class="back">
                  <FixedButton>
                    <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                  </FixedButton>
                </div>
              </form>
            }
          </div>
      </StyledFormPlayCode>
    )
  }
}

FormPlayCode.propTypes = {
  profile: PropTypes.object,
  playCode: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playCode: getPlayCode(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreatePlayCode: (subscriptionId, profileId, gameId, theme, minutes) => dispatch(createPlayCode(subscriptionId, profileId, gameId, theme, minutes)),
    fetchResetPlayCode: () => dispatch(resetPlayCode()),
    fetchReadGame: (gameId) => dispatch(readGame(gameId)),
    fetchResetGame: () => dispatch(resetGame())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPlayCode)
