import { put, call } from 'redux-saga/effects'
import { createProfile, readProfile, readProfileGames, updateProfile, reactivateProfileSubscription, upgradeProfileSubscription, repurchaseProfileSubscription, cancelProfileSubscription, forgottenPassword, readProfileSubscriptions, createProfileSubscription } from '../../server/api'
import * as types from '../actions/types'

export function* createProfileSaga({ payload }) {
  try {
    const profile = yield call(createProfile, payload)
    yield put({ type: types.CREATE_PROFILE_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.CREATE_PROFILE_ERROR, error })
  }
}

export function* readProfileSaga({ payload }) {
  try {
    const profile = yield call(readProfile, payload)
    yield put({ type: types.READ_PROFILE_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.READ_PROFILE_ERROR, error })
  }
}

export function* readProfileGamesSaga({ payload }) {
  try {
    const games = yield call(readProfileGames, payload)
    yield put({ type: types.READ_PROFILE_GAMES_RESPONSE, games })    
  } catch (error) {
    yield put({ type: types.READ_PROFILE_GAMES_ERROR, error })
  }
}

export function* updateProfileSaga({ payload }) {
  try {
    const profile = yield call(updateProfile, payload)
    yield put({ type: types.UPDATE_PROFILE_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_ERROR, error })
  }
}

export function* cancelProfileSubscriptionSaga({ payload }) {
  try {
    const profile = yield call(cancelProfileSubscription, payload)
    yield put({ type: types.CANCEL_PROFILE_SUBSCRIPTION_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.CANCEL_PROFILE_SUBSCRIPTION_ERROR, error })
  }
}

export function* reactivateProfileSubscriptionSaga({ payload }) {
  try {
    const profile = yield call(reactivateProfileSubscription, payload)
    yield put({ type: types.REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.REACTIVATE_PROFILE_SUBSCRIPTION_ERROR, error })
  }
}

export function* upgradeProfileSubscriptionSaga({ payload }) {
  try {
    const profile = yield call(upgradeProfileSubscription, payload)
    yield put({ type: types.UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.UPGRADE_PROFILE_SUBSCRIPTION_ERROR, error })
  }
}

export function* repurchaseProfileSubscriptionSaga({ payload }) {
  try {
    const profile = yield call(repurchaseProfileSubscription, payload)
    yield put({ type: types.REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE, profile })    
  } catch (error) {
    yield put({ type: types.REPURCHASE_PROFILE_SUBSCRIPTION_ERROR, error })
  }
}

export function* forgottenPasswordSaga({ payload }) {
  try {
    yield call(forgottenPassword, payload)
    yield put({ type: types.FORGOTTEN_PASSWORD_RESPONSE, payload })    
  } catch (error) {
    yield put({ type: types.FORGOTTEN_PASSWORD_ERROR, error })
  }
}

export function* createProfileSubscriptionSaga({ payload }) {
  try {
    const subscriptions = yield call(createProfileSubscription, payload)
    yield put({ type: types.CREATE_PROFILE_SUBSCRIPTION_RESPONSE, subscriptions })    
  } catch (error) {
    yield put({ type: types.CREATE_PROFILE_SUBSCRIPTION_ERROR, error })
  }
}