import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import StyledNavigation from './navigation.styled.js'

export class LoginNavigation extends Component {
  state = {
    menuOpen: false,
  }

  closeMenu = (e) => {
    e.preventDefault();
    this.setState({ menuOpen: false })
  }
  
  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen })
  }

  render() {
    return (
       <StyledNavigation theme={process.theme}>
         <div>
          <div id="menu-desktop">
            <ul>
              <li><Link to="/">Вход</Link></li>
              <li><Link to="/register">Регистрация</Link></li>
              <li><Link to="/recover">Забравена парола</Link></li>
              <li><Link to="/guide">Ръководство за игра</Link></li>
              <li><Link to="/tournament">Ръководство за турнир</Link></li>
              <li><Link to="/install">Инсталация на игрите</Link></li>
              <li><Link to="/games">Списък с игри</Link></li>
            </ul>
          </div>
          <div id="menu-mobile" onClick={this.closeMenu}>
            <Menu width={'100%'} isOpen={this.state.menuOpen}
                  onStateChange={(state) => this.handleStateChange(state)}>
              <Link to="/">Вход</Link>
              <Link to="/register">Регистрация</Link>
              <Link to="/recover">Забравена парола</Link>
              <Link to="/guide">Ръководство за игра</Link>
              <Link to="/tournament">Ръководство за турнир</Link>
              <Link to="/install">Инсталация на игрите</Link>
              <Link to="/games">Списък с игри</Link>
            </Menu>
          </div>
        </div>
        </StyledNavigation>
    )
  }
}

export default LoginNavigation