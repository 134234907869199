import React from 'react'
import StyledRequirements from './requirements.styled'

class Requirements extends React.Component {

  state = {
    expanded: false,
  }

  onToggle = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
  let style = (this.state.expanded) ? "expanded" : "notexpanded";
  return (
    <StyledRequirements>
      <section class="requirements">
        <div class="title" onClick={this.onToggle}>{this.props.title}</div>   
          <div class={style}>
            {this.props.children}
          </div>
      </section>
     </StyledRequirements>
    )
  }
}
export default Requirements
