import styled from 'styled-components'
const StyledEditGame = styled.section`
#editgame {

  padding-top: 50px;
  padding-bottom: 70px;

  .buttons {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .addparameter {
    padding-bottom: 50px;
  }

  .addrole {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  .addparameter button, .addrole button {
    width: 300px !important;
    margin: 0 auto;
  }

  .btn_delete {
    padding-bottom: 10px;
  }

  #player {
    margin-top: 70px;
  }
}

.resetinggame {
  font-size: 1.2em;
  text-align: center;
  padding: 100px;
}

.levelstitle, .parameterstitle, .rolestitle {
  text-align: center;
  padding-bottom: 40px;
  font-size: 2em;
  font-weight: bold;
  color: #009EC0;
}

.addparameterbtn, .addrolebtn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.addparameterbtns {
  padding-bottom: 50px;
}

.parameterstable button, .rolestable button {
  margin: 0 auto;
  width: 200px !important;
} 

.parameterstable {
  padding-bottom: 50px;
  width: 60%;
  margin: 0 auto;
}
.addlevel {
  margin-top: 40px;
}
`

export default StyledEditGame
