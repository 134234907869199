import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../../components/ui/title'
import Install from '../../components/pages/guides/sections/install'

export class InstallGuidePage extends Component {

  render() {
      return (
        <div>
          <PageTitle title="Инсталация на игрите"/>
          <Install />
        </div>   
      )
    }
}

InstallGuidePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallGuidePage)
