import { fromJS, Map } from 'immutable'

const SERVER = process.env.REACT_APP_SERVER

export async function createProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile`
  var body = `firstName=${payload.firstName}&secondName=${payload.secondName}&email=${payload.email.trim()}&password=${payload.password}&`
  body += (payload.activationCode) ? `activationCode=${payload.activationCode}&` : ``;
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile/email/${payload.email.trim()}`
  const token = btoa(`${payload.email.trim()}:${payload.password}`)
  localStorage.setItem('token', token)
  localStorage.setItem('profileEmail', payload.email.trim())
  localStorage.setItem('profilePassword', payload.password)
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}`
  var body = ``;
  body += (payload.firstName) ? `firstName=${payload.firstName}&` : ``;
  body += (payload.secondName) ? `secondName=${payload.secondName}&` : ``;
  body += (payload.email) ? `email=${payload.email.trim()}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.password) ? `password=${payload.password}&` : ``;
  body += (payload.activationCode) ? `activationCode=${payload.activationCode}&` : ``;  
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()  
  if (payload.email) {
    token = btoa(`${payload.email.trim()}:${payload.password}`)
    localStorage.setItem('token', token)
    localStorage.setItem('profileEmail', payload.email.trim())
    localStorage.setItem('profilePassword', payload.password)
  }  
  return await fetchedData
}

export async function cancelProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/cancel`
  var body = ``;
  body += (payload.profileId) ? `profileId=${payload.profileId}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function reactivateProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/reactivate`
  var body = ``;
  body += (payload.profileId) ? `profileId=${payload.profileId}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function upgradeProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/upgrade`
  var body = ``;
  body += (payload.profileId != null) ? `profileId=${payload.profileId}&` : ``;
  body += (payload.priceRef != null) ? `priceRef=${payload.priceRef}&` : ``;
  body += (payload.planType != null) ? `planType=${payload.planType}&` : ``;
  body += (payload.issueInvoice != null) ? `issueInvoice=${payload.issueInvoice}&` : ``;
  body += (payload.organizationId != null) ? `organizationId=${payload.organizationId}&` : ``;
  body += (payload.organizationName != null) ? `organizationName=${payload.organizationName}&` : ``;
  body += (payload.organizationCountry != null) ? `organizationCountry=${payload.organizationCountry}&` : ``;
  body += (payload.organizationTown != null) ? `organizationTown=${payload.organizationTown}&` : ``;
  body += (payload.organizationAddress != null) ? `organizationAddress=${payload.organizationAddress}&` : ``;
  body += (payload.organizationContactPerson != null) ? `organizationContactPerson=${payload.organizationContactPerson}&` : ``;
  body += (payload.organizationNote != null) ? `organizationNote=${payload.organizationNote}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function repurchaseProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/repurchase`
  var body = ``;
  body += (payload.profileId != null) ? `profileId=${payload.profileId}&` : ``;
  body += (payload.priceRef != null) ? `priceRef=${payload.priceRef}&` : ``;
  body += (payload.planType != null) ? `planType=${payload.planType}&` : ``;
  body += (payload.issueInvoice != null) ? `issueInvoice=${payload.issueInvoice}&` : ``;
  body += (payload.organizationId != null) ? `organizationId=${payload.organizationId}&` : ``;
  body += (payload.organizationName != null) ? `organizationName=${payload.organizationName}&` : ``;
  body += (payload.organizationCountry != null) ? `organizationCountry=${payload.organizationCountry}&` : ``;
  body += (payload.organizationTown != null) ? `organizationTown=${payload.organizationTown}&` : ``;
  body += (payload.organizationAddress != null) ? `organizationAddress=${payload.organizationAddress}&` : ``;
  body += (payload.organizationContactPerson != null) ? `organizationContactPerson=${payload.organizationContactPerson}&` : ``;
  body += (payload.organizationNote != null) ? `organizationNote=${payload.organizationNote}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createGame(payload) {
  const API_ENDPOINT = `${SERVER}/game`
  var body = `profileId=${payload.profileId}&title=${payload.title}&description=${payload.description}&subject=${payload.subject}&access=${payload.access}&theme=${payload.theme}&complexity=${payload.complexity}&`
  body += (payload.individualInstruction) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupInstruction) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.author) ? `author=${payload.author}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.grade) ? `grade=${payload.grade}&` : ``;
  body += (payload.introduction) ? `introduction=${payload.introduction}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readProfileGames(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}/games`;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}/subscription`
  const body = `subscriptionId=${payload.subscriptionId}&activationCode=${payload.activationCode}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  const token = localStorage.getItem('token');
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGameByAccessKey(payload) {
  const API_ENDPOINT = `${SERVER}/game/accesskey/${payload.accessKey}`
  const token = localStorage.getItem('token');
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function duplicateGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/duplicate/${payload.gameId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  var body = ``;
  body += (payload.title) ? `title=${payload.title}&` : ``;
  body += (payload.description) ? `description=${payload.description}&` : ``;
  body += (payload.subject) ? `subject=${payload.subject}&` : ``;
  body += (payload.grade) ? `grade=${payload.grade}&` : ``;
  body += (payload.author) ? `author=${payload.author}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.access) ? `access=${payload.access}&` : ``;
  body += (payload.theme) ? `theme=${payload.theme}&` : ``;
  body += (payload.complexity) ? `complexity=${payload.complexity}&` : ``;
  body += (payload.individualInstruction) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupInstruction) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.introduction) ? `introduction=${payload.introduction}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}/level`
  var body = ``;
  body += (payload.iText1 != null) ? `iText1=${payload.iText1}&` : ``;
  body += (payload.iText2 != null) ? `iText2=${payload.iText2}&` : ``;
  body += (payload.iText3 != null) ? `iText3=${payload.iText3}&` : ``;
  body += (payload.iText4 != null) ? `iText4=${payload.iText4}&` : ``;
  body += (payload.iText5 != null) ? `iText5=${payload.iText5}&` : ``;
  body += (payload.iText6 != null) ? `iText6=${payload.iText6}&` : ``;
  body += (payload.iText7 != null) ? `iText7=${payload.iText7}&` : ``;
  body += (payload.iText8 != null) ? `iText8=${payload.iText8}&` : ``;
  body += (payload.iText9 != null) ? `iText9=${payload.iText9}&` : ``;
  body += (payload.iText10 != null) ? `iText10=${payload.iText10}&` : ``;  
  body += (payload.iText11 != null) ? `iText11=${payload.iText11}&` : ``;
  body += (payload.iText12 != null) ? `iText12=${payload.iText12}&` : ``;
  body += (payload.iText13 != null) ? `iText13=${payload.iText13}&` : ``;
  body += (payload.iText14 != null) ? `iText14=${payload.iText14}&` : ``;
  body += (payload.iText15 != null) ? `iText15=${payload.iText15}&` : ``;
  body += (payload.iText16 != null) ? `iText16=${payload.iText16}&` : ``;
  body += (payload.iText17 != null) ? `iText17=${payload.iText17}&` : ``;
  body += (payload.iText18 != null) ? `iText18=${payload.iText18}&` : ``;
  body += (payload.iText19 != null) ? `iText19=${payload.iText19}&` : ``;
  body += (payload.iText20 != null) ? `iText20=${payload.iText20}&` : ``;
  body += (payload.iText21 != null) ? `iText21=${payload.iText21}&` : ``;
  body += (payload.iText22 != null) ? `iText22=${payload.iText22}&` : ``;
  body += (payload.iText23 != null) ? `iText23=${payload.iText23}&` : ``;
  body += (payload.iText24 != null) ? `iText24=${payload.iText24}&` : ``; 
  body += (payload.gAnswer1 != null) ? `gAnswer1=${payload.gAnswer1}&` : ``;
  body += (payload.gPoints1 != null) ? `gPoints1=${payload.gPoints1}&` : ``;
  body += (payload.gAnswer2 != null) ? `gAnswer2=${payload.gAnswer2}&` : ``;
  body += (payload.gPoints2 != null) ? `gPoints2=${payload.gPoints2}&` : ``;
  body += (payload.gAnswer3 != null) ? `gAnswer3=${payload.gAnswer3}&` : ``;
  body += (payload.gPoints3 != null) ? `gPoints3=${payload.gPoints3}&` : ``;
  body += (payload.gAnswer4 != null) ? `gAnswer4=${payload.gAnswer4}&` : ``;
  body += (payload.gPoints4 != null) ? `gPoints4=${payload.gPoints4}&` : ``;
  body += (payload.gAnswer5 != null) ? `gAnswer5=${payload.gAnswer5}&` : ``;
  body += (payload.gPoints5 != null) ? `gPoints5=${payload.gPoints5}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/level/${payload.levelId}`
  var body = ``;  
  body += (payload.iText1 != null) ? `iText1=${payload.iText1}&` : ``;
  body += (payload.iText2 != null) ? `iText2=${payload.iText2}&` : ``;
  body += (payload.iText3 != null) ? `iText3=${payload.iText3}&` : ``;
  body += (payload.iText4 != null) ? `iText4=${payload.iText4}&` : ``;
  body += (payload.iText5 != null) ? `iText5=${payload.iText5}&` : ``;
  body += (payload.iText6 != null) ? `iText6=${payload.iText6}&` : ``;
  body += (payload.iText7 != null) ? `iText7=${payload.iText7}&` : ``;
  body += (payload.iText8 != null) ? `iText8=${payload.iText8}&` : ``;
  body += (payload.iText9 != null) ? `iText9=${payload.iText9}&` : ``;
  body += (payload.iText10 != null) ? `iText10=${payload.iText10}&` : ``;
  body += (payload.iText11 != null) ? `iText11=${payload.iText11}&` : ``;
  body += (payload.iText12 != null) ? `iText12=${payload.iText12}&` : ``;
  body += (payload.iText13 != null) ? `iText13=${payload.iText13}&` : ``;
  body += (payload.iText14 != null) ? `iText14=${payload.iText14}&` : ``;
  body += (payload.iText15 != null) ? `iText15=${payload.iText15}&` : ``;
  body += (payload.iText16 != null) ? `iText16=${payload.iText16}&` : ``;
  body += (payload.iText17 != null) ? `iText17=${payload.iText17}&` : ``;
  body += (payload.iText18 != null) ? `iText18=${payload.iText18}&` : ``;
  body += (payload.iText19 != null) ? `iText19=${payload.iText19}&` : ``;
  body += (payload.iText20 != null) ? `iText20=${payload.iText20}&` : ``;
  body += (payload.iText21 != null) ? `iText21=${payload.iText21}&` : ``;
  body += (payload.iText22 != null) ? `iText22=${payload.iText22}&` : ``;
  body += (payload.iText23 != null) ? `iText23=${payload.iText23}&` : ``;
  body += (payload.iText24 != null) ? `iText24=${payload.iText24}&` : ``;
  body += (payload.gAnswer1 != null) ? `gAnswer1=${payload.gAnswer1}&` : ``;
  body += (payload.gPoints1 != null) ? `gPoints1=${payload.gPoints1}&` : ``;
  body += (payload.gAnswer2 != null) ? `gAnswer2=${payload.gAnswer2}&` : ``;
  body += (payload.gPoints2 != null) ? `gPoints2=${payload.gPoints2}&` : ``;
  body += (payload.gAnswer3 != null) ? `gAnswer3=${payload.gAnswer3}&` : ``;
  body += (payload.gPoints3 != null) ? `gPoints3=${payload.gPoints3}&` : ``;
  body += (payload.gAnswer4 != null) ? `gAnswer4=${payload.gAnswer4}&` : ``;
  body += (payload.gPoints4 != null) ? `gPoints4=${payload.gPoints4}&` : ``;
  body += (payload.gAnswer5 != null) ? `gAnswer5=${payload.gAnswer5}&` : ``;
  body += (payload.gPoints5 != null) ? `gPoints5=${payload.gPoints5}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/level/${payload.levelId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGames(payload) {
  const API_ENDPOINT = `${SERVER}/game/all`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function forgottenPassword(payload) {
  const API_ENDPOINT = `${SERVER}/email/forgottenPassword`
  var body = `email=${payload.trim()}`;
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode`
  var body = `profileId=${payload.profileId}&gameId=${payload.gameId}&minutes=${payload.minutes}&theme=${payload.theme}&isVirtual=${payload.isVirtual}&isConference=${payload.isConference}&conferencePosition=${payload.conferencePosition}&`
  body += (payload.subscriptionId) ? `subscriptionId=${payload.subscriptionId}&` : ""
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayCodes(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/profile/${payload.profileId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readLastPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/profile/${payload.profileId}/last`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}
