import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledRecover from './form.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { forgottenPassword } from '../../../state/actions/recover'

export class RecoverForm extends Component {

  state = {
    requestSent: false,
  }

  constructor(props) {
    super();
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    this.setState({
      [ id ]: value
    });
  }


  submitRegister = e => {
    e.preventDefault()
    this.props.fetchForgottenPassword(this.state.email);
    this.setState({
      requestSent: true
    });
  }

  render() {
    return (
      <StyledRecover>
        <div id="register">
          {this.state.requestSent &&
            <div class="confirmation">
              Изпратихме Ви email с подробности. Ако не го поличте до 24 часа, моля проверете си SPAM папката или ни се обадете на 0893691583.
            </div>
          }
          {!this.state.requestSent &&
            <form onSubmit={this.submitRegister}>
              <h2>Въведете Вашия email:</h2>
              <div class="inputs">              
                <div class="row">
                  <input tabIndex={1} id='email' required placeholder="*Email" onChange={this.handleInputChange}/>
                </div>
              </div>
              <div class='required'>* задължително поле</div>
              <div class='submit'>
                <FixedButton>
                  <StandardButton isSubmit text="Възстановете" width="100%"/>
                </FixedButton>
              </div>
            </form>
          }      
      </div>
      </StyledRecover>
    )
  }
}

RecoverForm.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchForgottenPassword: (email) => dispatch(forgottenPassword(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverForm)
