import React from 'react'
import StyledTheme from './theme.styled'
import { Link } from 'react-router-dom'

class Theme extends React.Component {
  
  render() {
  return (
    <StyledTheme theme={process.theme}>
      <div>
        <img class="theme" src={"/images/themes/" + this.props.id + ".gif"} alt="Графична тема на играта" />
      </div>
    </StyledTheme>
    )
  }
}
export default Theme
