
import styled from 'styled-components'

const StyledPageTitle = styled.section`

.pagetitle {
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 70px;

  @media print {
    display:none;
  }
}

.title {
  font-size: 2.5em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 50px;
  margin: 0;
}

.desc {
  font-size: 1.2em;
  padding-top: 20px;
  padding: 0 50px;
}

@media (min-width: 1200px) {

  .pagetitle {
    padding-top: 180px;
  }

  .title {
    padding: 0px;
  }

  .desc {
    padding: 20px 0 0 0;
  }
}

`
export default StyledPageTitle