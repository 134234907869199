import React from 'react'
import StyledPageTitle from './title.styled'
import Content from './content'

class PageTitle extends React.Component {
  
  render() {
  let bgr = (this.props.bgr) ? this.props.bgr : process.theme.titleBgrColor;
  let color = (this.props.color) ? this.props.color : process.theme.titleColor;
  return (
    <StyledPageTitle>
      <div class="pagetitle" style={{ background: bgr, color: color }}>
        <Content>
          <h1 class="title">{this.props.title}</h1>
          {this.props.desc &&
            <div className="desc">{this.props.desc}</div>
          }
        </Content>
      </div>
    </StyledPageTitle>
    )
  }
}
export default PageTitle
