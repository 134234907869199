import React from 'react'
import StyledGuide from '../guide.styled'
import StyledPhysical from './android.styled'
import Section from '../../../ui/section'
import GuideSubTitle from '../subtitle'
import Content from '../../../ui/content'

class Android extends React.Component { 
  render() {
  let i18n = this.props.i18n;
  return (
    <StyledGuide>
      <StyledPhysical>
          <Content>
            <div>
              <h3>Стъпка 1: ОТВОРЕТЕ CHROME БРАУЗЪР</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen1.jpg" alt="Отворете Chrome браузър"/>
                  <div class={["podlojka", "p1_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Отворете браузър Chrome</h4>
                  <div>Иконата на Chrome браузъра може да бъде на началния екран на таблета или в списъка с приложения.</div>
                  <div><span class="info">Инфо: </span>Може да използвате и алтернативен браузър.</div>  
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 2: ОТВОРЕТЕ KOD.PUK.BG</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen2.jpg" alt="Заредете kod.puk.bg"/>
                  <div class={["podlojka", "p2_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Заредете адрес <a href="https://kod.puk.bg" target="_blank">kod.puk.bg</a></h4>
                </div>
              </div>
            </div>
            
            <div>
              <h3>Стъпка 3: ИЗБЕРЕТЕ „ИНСТАЛАЦИЯ НА ИГРИТЕ“ ОТ МЕНЮТО</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen3.jpg" alt="Изберете от менюто"/>
                  <div class={["podlojka", "p3_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p3_2"].join(' ')}><div>2</div></div>
                </div>
                <div class="left">    
                  <h4>1. Отворете менюто за навигация</h4>
                  <h4>2. Изберете „Инсталационни пакети“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 4: ИЗБЕРЕТЕ „СВАЛИ“</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen4.jpg" alt="Свалете приложението"/>
                  <div class={["podlojka", "p4_1"].join(' ')}><div>1</div></div>
                  <div class={["podlojka", "p4_2"].join(' ')}><div>2</div></div>
                </div>
                <div class="left">    
                  <h4>1. Последна актуална версия на Пук.бг</h4>
                  <h4>2. Изберете „Свали“ от секцията Android</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 5: ПОЗВОЛЕТЕ НА БРАУЗЪРА ДА ЗАПАСИ ИНСТАЛАЦИОННИЯ ПАКЕТ НА ВАШЕТО УСТРОЙСТВО*</h3>
              <div class="asterix">*вързможно е браузърът Ви вече да има такива права. В този случай тази стъпка се пропуска.</div>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen5.jpg" alt="Позволете запис"/>
                  <div class={["podlojka", "p5_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „ALLOW“ / „РАЗРЕШАВАМ“</h4>
                </div>
              </div>
            </div>
            
            <div>
              <h3>Стъпка 6: ИЗЧАКАЙТЕ, ДОКАТО СЕ ИЗТЕГЛИ ИНСТАЛАЦИОННИЯ ПАКЕТ</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen6.jpg" alt="Изчакайте изтеглянето"/>
                  <div class={["podlojka", "p6_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Индикатор, че инсталационния пакет се изтегля в момента.</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 7: ОТВОРЕТЕ ИНСТАЛАЦИОННИЯ ПАКЕТ</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen7.jpg" alt="Отворете инсталационния пакет"/>
                  <div class={["podlojka", "p7_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „Open“ / „Отвори“.</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 8: ОТВОРЕТЕ НАСТРОЙКИТЕ ЗА ИНСТАЛАЦИЯ ОТ „НЕИЗВЕСТЕН ИЗТОЧНИК“</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen8.png" alt="Отворете настройките"/>
                  <div class={["podlojka", "p8_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „Settings“ / „Настройки“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 9: РАЗРЕШЕТЕ ИНСТАЛИРАНЕТО ОТ „НЕИЗВЕСТЕН ИЗТОЧНИК“</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen9.png" alt="Разрешете нейзвестен източник"/>
                  <div class={["podlojka", "p9_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Включете опцията.</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 10: НАТИСНЕТЕ „INSTALL“</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen10.jpg" alt="Стартирайте инсталацията"/>
                  <div class={["podlojka", "p10_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „Install“ / „Инсталирай“</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 11: ИЗЧАКАЙТЕ, ДОКАТО ИНСТАЛАЦИЯТА ПРИКЛЮЧИ</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen11.jpg" alt="Изчакайте инсталацията"/>
                  <div class={["podlojka", "p11_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Индикатор, че приложението на Пук.бг се интсалира.</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 12: НАТИСНЕТЕ „DONE“</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen12.jpg" alt="Натиснете готово"/>
                  <div class={["podlojka", "p12_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете „Done“, когато инсталацията приключи.</h4>
                </div>
              </div>
            </div>

            <div>
              <h3>Стъпка 13: ОТВОРЕТЕ ПРИЛОЖЕНИЕТО PUK.BG, ЗА ДА СЕ УВЕРИТЕ, ЧЕ ВСИЧКО Е НАРЕД</h3>
              <div class="section1">
                <div class={["right", "tablet1"].join(' ')}>
                  <img class="screen" src="/images/guides/install/android/screen13.jpg" alt="Отворете приложението"/>
                  <div class={["podlojka", "p13_1"].join(' ')}><div>1</div></div>
                </div>
                <div class="left">    
                  <h4>1. Натиснете иконата на puk.bg, за да стартирате приложението.</h4>
                </div>
              </div>
            </div>
          </Content>
      </StyledPhysical>
    </StyledGuide>
    )
  }
}
export default Android
