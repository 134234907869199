import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import StyledLevel from './level.styled'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from 'react-accessible-accordion';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export class Level extends Component {
  
    constructor(props) {
      super()
    }

    render() {
        let {level, markAnswerId, givenAnswers } = this.props;
        const id = (level.getIn(['Id'], null) == null) ? "-" : level.getIn(['Id'], null);
        const groupQuestionAnswers = level.getIn(['GroupQuestion','Answers'], []);
        const individualQuestions = level.getIn(['IndividualQuestions','IndividualQuestion'], []);
        const individualInputs = [];
        individualQuestions.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx) => {
            const individualQuestionsAnswers = val.getIn(['Answers'], null);
            individualQuestionsAnswers.map((val, idy) => {
                const answerValue = val.getIn(['Value'], null);
                individualInputs.push(<div class="answer_detail"><div class="answer_title">Индивидуален текст {idx + 1}:</div><div class="answer_value">{answerValue}</div></div>);     
            });
        });
        
        let headingclass = "";
        if (this.props.id % 2 == 0) {
            headingclass = "accordion__heading_1";
        } else {
            headingclass = "accordion__heading_2";
        }

        var preExpanded = [];
        if (this.props.preExpanded) {
            preExpanded = [this.props.id]
        }
        
        return (
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={preExpanded}>
                <AccordionItem uuid={this.props.id} dangerouslySetExpanded={true}>
                    <StyledLevel theme={process.theme}>
                    <div class={headingclass}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            {this.props.isPlayedLevel && this.props.isPositivePoints &&
                                <span class="green">
                                    Ниво {this.props.id + 1} 
                                </span>
                            }
                            {this.props.isPlayedLevel && !this.props.isPositivePoints && 
                                <span class="red">
                                    Ниво {this.props.id + 1}
                                </span>
                            }
                            {!this.props.isPlayedLevel &&
                            <span>
                                Ниво {this.props.id + 1}
                            </span>
                            }
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div class="level_inputs">
                                <div>
                                    <div>
                                        <div class="level_inputs_title">Индивидуални текстове, които ще бъдат разпределени между играчите:</div>
                                        <div key={this.props.id}>
                                        {individualInputs}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="level_inputs_title">Възможни отговори на задачата, която групата трябва да реши с пълен консенсус:</div>
                                    {
                                        groupQuestionAnswers.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).sort((a, b) => b.getIn(['IsTrue'], null) - a.getIn(['IsTrue'], null)).map((val, idx)=> {
                                        const answerId = val.getIn(['Id'], null);
                                        const answerValue = val.getIn(['Value'], null);
                                        const answerPoints = val.getIn(['Points'], null);
                                        const answerPointsClass = (answerPoints > 0) ? "positive_points" : "negative_points"; 
                                        return (<div class="answer_detail">
                                                    {answerId == markAnswerId &&
                                                        <div class="answer_title"><b>Посочен отговор ({idx + 1}): </b></div>
                                                    }
                                                    {answerId != markAnswerId &&
                                                        <div class="answer_title">Oтговор {idx + 1}: </div>
                                                    }
                                                    <div class="answer_value">{answerValue}</div>
                                                    <div class="answer_points"><span class={answerPointsClass}>({answerPoints} точки)</span></div>
                                                </div>
                                            )
                                        
                                        })
                                    }
                                </div>
                                
                                {givenAnswers &&
                                    <div>
                                        <div class="level_inputs_title">Индивидуални отговори:</div>
                                        <div class="table">
                                            <TableContainer>
                                            <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Отговор</TableCell>
                                                    <TableCell align="center">Брой посочвания</TableCell>
                                                    <TableCell align="center">Посочили играчи</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {givenAnswers.sort((a, b) => b.getIn(['Count'], null) - a.getIn(['Count'], null)).map((val, idy)=> {
                                                var value = val.getIn(["Value"], null);
                                                var nicknames = val.getIn(["Nicknames"], null);
                                                var count = val.getIn(["Count"], null);
                                                return (
                                                    <TableRow>
                                                    <TableCell align="center">{value}</TableCell>
                                                    <TableCell align="center">{count}</TableCell>
                                                    <TableCell align="center">{nicknames}</TableCell>
                                                    </TableRow>
                                                )})
                                            }     
                                            </TableBody>
                                            </Table>
                                        </TableContainer>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                        </AccordionItemPanel>
                    </div>
                    </StyledLevel>
                </AccordionItem>
            </Accordion>
        )
    }
}

Level.propTypes = {
    gameId: PropTypes.string,
    id: PropTypes.string,
    sequence: PropTypes.string,
    level: PropTypes.object,
    markAnswerId: PropTypes.string,
    givenAnswers: PropTypes.object,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Level)