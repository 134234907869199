import styled from 'styled-components'

const StyledEditLevel = styled.section`

.roleLevels {
  padding-bottom: 50px;
}

.roleLevelsTitle {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #F26173;
}

.level_inputs_title {
  font-size: 1.3em;
  font-weight: bold;
  padding: 10px 0px;
}

.deleted_answer {
  font-size: 1.2em;
  color: #F26173;
  font-style: italic;
  padding-top: 10px;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row div {
  width: 42%;
}

.single {
  width: 93%;
  margin-left: -18px;
}

.first {
  margin-left: -40px;
}

.second {
  margin-left: 50px;
}

.level_desc {
  font-size: 0.7em;
  color: #000;
  font-weight: normal;
}

.checkbox {
  width: 100% !important;
  margin-bottom: 10px;
}

.list .first {
  width: 42%;
  margin-left: 5px;
}

.list .second {
  width: 15%;
}
.list .third {
  width: 15%;
  margin-left: 50px;
}

.list .rank {
  font-weight: bold;
  font-size: 1.5em;
  text-align: right;
  width: 35px;
  margin-left: -50px;
}

`

export default StyledEditLevel
