import styled from 'styled-components'

const StyledTitle = styled.div`
.title {
  font-size: 1.60em;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  padding-top: 0px;
  margin: 0px;
  text-align: center;
}

@media (min-width: 1200px) {

  .title {
    font-size: 2.250em;
  }
  
}
@media print {
  .title {
    font-size: 3em;
    font-weight: bold;
    text-align: center;
  }
}
  
`

export default StyledTitle
