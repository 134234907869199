import styled from 'styled-components'

const StyledLogo = styled.div`

 margin: 0 auto;

 img {
   width: 230px;
   margin: 20px;
   padding-top: 15px;
    
    @media (min-width: 1200px) {
      width: 230px;
      padding: 7px;
      margin: 0px 0px 0px 100px;
    }
 }
`

export default StyledLogo
