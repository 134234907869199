import * as types from '../actions/types'
import initial from './initial'
import { setGames, setError } from '../selectors/game'

export default function (state = initial.games, action) {
  switch (action.type) {
    case types.READ_GAMES_RESPONSE:
    case types.READ_PROFILE_GAMES_RESPONSE:
      return setGames(state, action)
    case types.CREATE_GAME_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
