import styled from 'styled-components'
const StyledDuplicateGame = styled.section`

.question {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    padding: 50px;
}

.yes, .no {
    padding-bottom: 10px;
}

.back {
    padding-bottom: 70px;
}
`

export default StyledDuplicateGame
