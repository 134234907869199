import { combineReducers } from 'redux'
import * as types from '../state/actions/types'

import profile from '../state/reducers/profile'
import game from '../state/reducers/game'
import games from '../state/reducers/games'
import playCode from '../state/reducers/playcode'
import playCodes from '../state/reducers/playcodes'

const appReducer = combineReducers({
  profile,
  game,
  games,
  playCode,
  playCodes,
})

const rootReducer = (state, action) => {
  if (action.type === types.AUTH_LOGOUT) {
    state = undefined
    localStorage.removeItem("token");    
    localStorage.removeItem("profileEmail");
    localStorage.removeItem("profilePassword");
  }
  return appReducer(state, action)
}


export default rootReducer
