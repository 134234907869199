
import * as types from '../actions/types'
import initial from './initial'
import { setPlayCodes, setPlayCodesError } from '../selectors/playcode'

export default function (state = initial.playCodes, action) {
  switch (action.type) {
    case types.READ_PLAYCODES_RESPONSE:
      return setPlayCodes(state, action)
    case types.READ_PLAYCODES_ERROR:
      return setPlayCodesError(state, action.error)
    default:
      return state
  }
}
