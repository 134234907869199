import styled from 'styled-components'

const StyledTitle = styled.div`

.guidetitle {
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 70px;
}

.title {
  font-size: 2.7em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 50px;
  margin: 0;
}

.version {
  font-size: 1.5em;
  padding-top: 20px;
  padding: 20px 50px 0 50px;
  font-weight: bold;
}

@media (min-width: 1200px) {

  .guidetitle {
    padding-top: 160px;
  }

  .title {
    padding: 0px;
    width: 800px;
    margin: 0 auto;
  }

}

@media print {
  .guidetitle {
    margin-top: 350px;
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .version {
    padding-top: 50px;
    font-size: 1.5em;
  }
}
  
`

export default StyledTitle
