import styled from 'styled-components'

const StyledLoginDialog = styled.div`
  
  #login {
    margin-bottom: 0px;
  }

  form {
    margin-bottom: 50px;
  }

  a {
    font-size: 1.1em;
  }

  .links {
    text-align: center;
  }

  .change {
    margin-top: 30px;
  }
  
`

export default StyledLoginDialog
