import styled from 'styled-components'

const StyledSection = styled.section`

.section {
  padding: 70px 0px;
  text-align: center;

  @media print {
    display: ${props => props.noPrint ? "none" : 'block'} ;
  }
}

@media (min-width: 1200px) {
  .section {
    text-align: left;
  }
}

`
export default StyledSection