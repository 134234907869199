import styled from 'styled-components'

const StyledGuide = styled.div`
  
h3 {
  width: 80%;
  margin: 0 auto;
  color: #009ec0;
  padding-top: 30px;
  page-break-before: always;
  break-before: always;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}

.details h4 {
  font-weight: bold;  
}

.section1 .right {
  zoom: 0.35;
  -moz-transform: scale(0.35);
}

.section2 .podlojka {
  background-image: url("/images/guides/podlojka.png");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: left top;
  width: 40px;
  padding-top: 8px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.tablet1 {
  position: relative;
  background-image: url("/images/guides/tablet1.png");
  background-repeat: no-repeat;
  background-size: 877px 545px;
  background-position: left top;
  width: 877px;
  height: 545px;
  margin: 250px auto;
  -webkit-print-color-adjust: exact;
}

.tablet1 .screen {
  padding-top:38px;
  padding-bottom:35px;
  padding-left:60px;
  width: 850px;
  padding-right:40px;
}

.tablet2 {
  position: relative;
  background-image: url("/images/guides/tablet2.png");
  background-repeat: no-repeat;
  background-size: 300px 525px;
  background-position: left top;
  width: 300px;
  margin: 70px auto 70px auto;
  -webkit-print-color-adjust: exact; 
}

.tablet2 .screen {
  padding-top:36px;
  padding-bottom:35px;
  padding-left:24px;
  width: 317px;
  padding-right:40px;
}

.section1 .podlojka {
  background-image: url("/images/guides/podlojka.png");
  background-repeat: no-repeat;
  background-size: 60px 65px;
  background-position: left top;
  width: 60px;
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
}


.left {
  margin: 0 20px;
  text-align: left;   
}

.left div {
  margin: 20px 0;
}

.info {
  color: #fff;
  background-color: #009ec0;
  padding: 2px 10px;
  border-radius: 25px;
  margin-right: 5px;
}

.attention {
  color: #fff;
  background-color: #F26173;
  padding: 2px 10px;
  border-radius: 25px;
  margin-right: 5px;
}

.continue {
  margin-top: -20px;
}

.complexityImages {
  text-align: center;
}

.complexityImages img {
    margin: 20px;
}

.monitor {
  position: relative;
  background-image: url("/images/guides/monitor.png");
  background-repeat: no-repeat;
  background-size: 900px 650px;
  background-position: left top;
  width: 900px;
  height: 650px;
  margin: 40px auto;
  -webkit-print-color-adjust: exact;
}

.monitor .url {
  position: absolute;
  left: 200px;
  top: 66px;
  font-size: 0.8em;
}

.monitor .screen {
  padding-top:97px;
  padding-bottom:35px;
  padding-left:54px;
  width: 885px;
  height: 507px;
  padding-right:40px;
  border-radius: 0px 0px 50px 50px;
}

.asterix {
  color: #009ec0;
  padding-top: 5px;
  padding-bottom: 15px;
  text-align: center;
}

@media (min-width: 1200px) {

  .section1 .right {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      zoom: 1;
      -moz-transform: scale(1);
  }

  .section2 .left {
      width: 800px;
  }

  .tablet1 {
      margin: 70px auto 70px auto;
  }

  .section1 .podlojka div {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  .section2 {
      display: flex;
      align-items: top;
      justify-content: center;
  }

  .tablet2 {
      margin: 20px;
  }
}

@media print {

  .details {
      text-align: center;
  }

  .section1 .right {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      zoom: 1;
      -moz-transform: scale(1);
  }

  .tablet1 {
      margin: 70px auto 70px auto;
  }

  .section1 .podlojka div {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  .youtube { 
      display: none;
  }

  .monitor {
      margin: 70px auto 70px auto;
  }
}
  
`

export default StyledGuide
