import styled from 'styled-components'

const StyledPreparations = styled.div`

.teams {
  text-align: center;
  padding: 50px 0px;
}

.p1_1 {
    position: absolute;
    left: 135px;
    top: 40px;
  }
  
  .p1_2 {
    position: absolute;
    left: 430px;
    top: 320px;
  }
  
  .p1_3 {
    position: absolute;
    left: 510px;
    top: 400px;
  }
  
  .p2_1 {
    position: absolute;
    left: 510px;
    top: 330px;
  }

  .p31_1 {
    position: absolute;
    left: 500px;
    top: 270px;
  }
  
  .p3_1 {
    position: absolute;
    left: 170px;
    top: 160px;
  }
  
  .p3_2 {
    position: absolute;
    left: 80px;
    top: 390px;
  }
  
  .p3_3 {
    position: absolute;
    left: 730px;
    top: 430px;
  }
  
  .p4_1 {
    position: absolute;
    left: 590px;
    top: 240px;
  }
  
  .p4_2 {
    position: absolute;
    left: 240px;
    top: 320px;
  }
  
  .p4_3 {
    position: absolute;
    left: 470px;
    top: 360px;
  }
  
  .p4_4 {
    position: absolute;
    left: 330px;
    top: 370px;
  }

  .p4_v_1 {
    position: absolute;
    left: 320px;
    top: 150px;
  }

  .p4_v_2 {
    position: absolute;
    left: 520px;
    top: 190px;
  }

  .p4_v_3 {
    position: absolute;
    left: 310px;
    top: 210px;
  }

  .p4_v_4 {
    position: absolute;
    left: 470px;
    top: 240px;
  }

  .p4_v_5 {
    position: absolute;
    left: 340px;
    top: 270px;
  }

  .p4_v_6 {
    position: absolute;
    left: 500px;
    top: 310px;
  }

  .p4_v_7 {
    position: absolute;
    left: 340px;
    top: 360px;
  }

  .p4_v_8 {
    position: absolute;
    left: 470px;
    top: 400px;
  }
  
  .p5_1 {
    position: absolute;
    left: 330px;
    top: 220px;
  }
  
  .p5_2 {
    position: absolute;
    left: 500px;
    top: 400px;
  }
  
  .p5_3 {
    position: absolute;
    left: 320px;
    top: 420px;
  }
  
  .p5_v_1 {
    position: absolute;
    left: 340px;
    top: 110px;
  }
  
  .p5_v_2 {
    position: absolute;
    left: 480px;
    top: 150px;
  }
  
  .p5_v_3 {
    position: absolute;
    left: 320px;
    top: 210px;
  }

  .p6_1 {
    position: absolute;
    left: 340px;
    top: 110px;
  }

  .p6_2 {
    position: absolute;
    left: 480px;
    top: 140px;
  }

  .p6_3 {
    position: absolute;
    left: 550px;
    top: 160px;
  }

  .p6_4 {
    position: absolute;
    left: 280px;
    top: 200px;
  }
  
  .p6_5 {
    position: absolute;
    left: 350px;
    top: 240px;
  }
`

export default StyledPreparations
