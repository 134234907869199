import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import StyledDuplicateGame from './duplicate.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'
import {  getGame } from '../../../state/selectors/game'

import { resetGame, duplicateGame } from '../../../state/actions/game'

import queryString from 'query-string'
import history from '../../../history'

export class DuplicateGame extends Component {
  
  constructor(props) {
    super()

    // reset game
    props.fetchResetGame();

    const values = queryString.parse(window.location.search);
    var gameId = "";
    if (values.gameId != null && values.gameId != "") {
      gameId = values.gameId;
    } else if (values.gameid != null && values.gameid != "") {
      gameId = values.gameid;
    } else if (values.id != null && values.id != "") {
      gameId = values.id;
    }
    this.state = {
      gameId: gameId,
      isDuplicating: false,
    }

    this.duplicateGame = this.duplicateGame.bind(this);
  }

  duplicateGame = e => {
    e.preventDefault()    
    this.props.fetchDuplicateGame(this.state.gameId)
    this.setState({
      isDuplicating: true,
    })
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      const { game, profile } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      return (
      <StyledDuplicateGame>
        {this.state.isDuplicating && gameId != null &&
            <Redirect to={'/game?id=' + gameId} />
        }
        {this.state.isDuplicating && 
          <div class="duplicating">
            В момента се създава дубликат на играта. Моля, изчакайте.
          </div>
        }
        {!this.state.isDuplicating &&
          <div class="duplicate">
            <div class="question">Сигурни ли сте, че искате да дублирате игра <Link to={'/game?id=' + this.state.gameId}>{this.state.gameId}</Link>?</div>
            <div class="btns">
              <div class="yes">
                <FixedButton>
                  <StandardButton click={this.duplicateGame} text="Да" width="100%" />
                </FixedButton>
              </div>
              <div class="no">
                <FixedButton>
                  <StandardButton href="/" text="Не" width="100%" type="delete"/>
                </FixedButton>
              </div>
              <div class="back">
                <FixedButton>
                  <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                </FixedButton>
              </div>
            </div>
          </div>
        }
      </StyledDuplicateGame>
    )
  }
}

DuplicateGame.propTypes = {
  game: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchResetGame: () => dispatch(resetGame()),
    fetchDuplicateGame: (gameId) => dispatch(duplicateGame(gameId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateGame)
