import styled from 'styled-components'

const StyledPlayer = styled.section`
.player {
  height: 100%;
  background-color: #012a32;
}

.lprogress {
  background-color: #012a32;
  font-weight: bold;
  font-size: 1.70em;
  color: #ffffff;
  position:absolute;
  left: 45%;
  top: 45%;
  transform: translateY(-50%);
  z-index:1000;
}

.nounity {
  color: #000;
  text-align: center;
  padding: 0 50px;
  font-size: 1.2em;
}

.actions {
  background-color: black;
  width: 100%;
  height: 12px;
  position: absolute;
  z-index: 9999999;
}

.actionsexpanded {
  background-color: black;
  width: 100%;
  height: 44px;
  position: absolute;
  z-index: 9999999;
}

.arrowdown {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 4px;
  left: 49%;
}

.arrowup {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  position: absolute;
  bottom: 4px;
  left: 49%;
}

.actionsexpanded .row {
  margin-top: -15px;
}

.actionsexpanded label {
  color: white !important;
}

@media (min-width: 1200px) {

  .lprogress {    
    font-size: 2.250em;
  }

  .plot {
    display:flex;
    align-content: center;
    align-items: center;
  }

  .topunity {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #012a32;
  }

  .rightunity {
    position: relative;
    width: 965px;
    margin: 0 auto;
    padding: 0;
    background-color: #012a32;
  }
  
  .nounity {
    color: #fff;
    height: 100%;
    text-align: center;
    padding-top: 200px;
    width: 80%;
    margin: 0 auto;
  }
}
`
export default StyledPlayer
