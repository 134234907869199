import React from 'react'
import StyledRequirements from './requirements.styled'

class Requirement extends React.Component {

  render() {
  return (
    <StyledRequirements>
      <div class="item">
        <div class="requirement">{this.props.children}</div>
        {this.props.attention &&
          <div class="attention">{this.props.attention}</div>
        }
      </div>
    </StyledRequirements>
    )
  }
}
export default Requirement
