import React from 'react'
import StyledContent from './content.styled'

export default function Content({
  children
}) {
  return (
    <StyledContent>
      <div class="content">      
        {children}
      </div>
    </StyledContent>
  )
}
