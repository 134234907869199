import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'
import queryString from 'query-string'

import StyledGames from './games.styled'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

import {  getGames } from '../../../state/selectors/game'
import { readGames, resetGame } from '../../../state/actions/game'
import {  getProfile } from '../../../state/selectors/profile'
import { resetPlayCode } from '../../../state/actions/playcode'
import { readProfileGames } from '../../../state/actions/profile'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import {
  isMobile,
} from "react-device-detect";


function sortGames(a, b, prop, desc) {
  var a_prop = a.get(prop);
  var b_prop = b.get(prop);
  var reverse = 1;
  if (desc) {
    reverse = -1;
  }
  if (a_prop < b_prop) {
    return reverse * -1;
  }
  if (a_prop > b_prop) {
    return reverse * 1;
  }
  return 0;
}

function filterGames(a, props, values) {
  if (props.length > 0) {
    var isValid = 1;
    props.map((prop, index) => {
      if (a.get(prop) != values[index]) {
        isValid = 0;
      }
    });
    return isValid;
  } else {
    return 1;
  }
}

function isSelected(prop, value, props, values) {
  if (props.length > 0) {
    var isSelected = false;
    props.map((filterProp, index) => {
      if (filterProp == prop && values[index] == value) {
        isSelected = true;
      }
    });
    return isSelected;
  } else {
    return false;
  }
}

export class Games extends Component {
  constructor(props) {
    super()

    if (props.isLimitedToProfile) {
      const { profile } = props;
      const profileId = profile.getIn(['Profile', 'Id'], null);
      props.fetchReadProfileGames(profileId);
    } else {
      props.fetchGames()
    }    

    props.fetchResetGame();

    this.state = {
      sortProp: "CreationDate",
      desc: false,
      filterProp: "",
      filterValue: "",
      filterProps: [],
      filterValues: [],
      rowsPerPage: 10,
      page: 0,
    }

    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleLangChange = this.handleLangChange.bind(this);
  }

  componentWillMount() {
    const { profile } = this.props;
    const subscription = profile.getIn(['Profile', 'Subscription'], null);
    if (subscription == null) {
      this.setState({
        sortProp: "IsFree",
        desc: true,
      })
    }

    var props = [];
    var values = [];
    const params = new URLSearchParams(window.location.hash.split("?")[1]);
    const items = Array.from(params.entries()).map(item => {
        props.push(item[0]);
        values.push(item[1]);
      }
    );
    this.setState({
      filterProps: props,
      filterValues: values,
    });
  }

  onSortingChanged(prop) {
    if (this.state.desc) {
      this.setState({
        desc: false, 
        sortProp: prop,
      });
    } else {
      this.setState({
        desc: true,
        sortProp: prop,
      });
    }
  }

  onFilter(prop, value) {
    var props = [...this.state.filterProps];
    var values = [...this.state.filterValues];
    var isApplied = false;
    var appliedIndex = -1;
    const params = new URLSearchParams();
    props.map((filterProp, index) => {
      if (filterProp == prop && values[index] == value) {
        isApplied = true;
        appliedIndex = index;
      }
      if (filterProp == prop && prop == "Grade" && values[index] != value) {
        props.splice(appliedIndex, 1);
        values.splice(appliedIndex, 1);
      }
    });
    if (isApplied) {
      props.splice(appliedIndex, 1);
      values.splice(appliedIndex, 1);
    } else {
      props.push(prop);
      values.push(value);
    }
    props.map((filterProp, index) => {
      params.append(filterProp, values[index]);
    });
    window.location.hash = `?${params.toString()}`;
    this.setState({
      filterProps: props,
      filterValues: values,
    });
  }

  onClearFilter() {
    var props = [...this.state.filterProps];
    var values = [...this.state.filterValues];
    let gradeValue = "";
    props.map((filterProp, index) => {
      if (filterProp == "Grade") {
        gradeValue = values[index];
      }
    });
    window.location.hash = `?Grade=${gradeValue}`;
    this.setState({
      filterProps: ["Grade"],
      filterValues: [gradeValue],
    });
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  goBack = () => {
    history.go(-1);
  }

  handleGradeChange = (event) => {
    this.state.filterProps = [];
    this.state.filterValues = [];
    this.onFilter("Grade", event.target.value);
  };

  handleLangChange = (event) => {
    this.state.filterProps = [];
    this.state.filterValues = [];
    this.onFilter("Lang", event.target.value);
  };

  render() {

      const { games, profile } = this.props
      
      const gamesList = games.getIn(['games','Games', 'Game']);

      var grade = "";
      this.state.filterProps.map((filterProp, index) => {
        if (filterProp == "Grade") {
          grade =this.state.filterValues[index];
        }
      });

      const uniqueGrades = ["3. група", "4. група", "1. клас", "2. клас", "3. клас", "4. клас", "Бъди умен, мисли кръгово"];
      
      var lang = "";
      this.state.filterProps.map((filterProp, index) => {
        if (filterProp == "Lang") {
          lang =this.state.filterValues[index];
        }
      });
      
      const uniqueLangs = [];
      if (gamesList) {
        gamesList.map((game) => {
            if (uniqueLangs.indexOf(game.get('Lang')) === -1) {
              uniqueLangs.push(game.get('Lang'))
            }
        });
      }

      if (uniqueLangs.length == 1) {
        lang = uniqueLangs[0];
      }
      
      const uniqueComplexities = [];
      if (gamesList) {
        gamesList.filter(a => filterGames(a, this.state.filterProps, this.state.filterValues)).map((game) => {
            if (uniqueComplexities.indexOf(game.get('Complexity')) === -1) {
              uniqueComplexities.push(game.get('Complexity'))
            }
        });
      }

      const uniqueSubjects = [];
      if (gamesList) {
        gamesList.filter(a => filterGames(a, this.state.filterProps, this.state.filterValues)).map((game) => {
            if (uniqueSubjects.indexOf(game.get('Subject').trim()) === -1) {
              uniqueSubjects.push(game.get('Subject'))
            }
        });
      }

      const profileId = profile.getIn(['Profile', 'Id'], null);
      const subscription = profile.getIn(['Profile', 'Subscription'], null);
      var isActive = (subscription) ? subscription.getIn(["IsActive"]) : false;
      var playedGames = profile.getIn(['Profile', 'PlayedGames'], null);
      return (
        <StyledGames theme={process.theme}>
        
        <div class="select">
          <select value={grade}  onChange={this.handleGradeChange}>
            <option value="" disabled selected>Изберете възраст*</option>
            {uniqueGrades ?
              uniqueGrades.map((grade) => {
              return (
                <option value={grade}>{grade}</option>
              )
              }) : '' 
            }
          </select>
        </div>

        {grade != "" &&
         <div id="games">
          {(!gamesList || gamesList.size == 0) &&
            <div class="message">
              Списъкът с игри е празен.
            </div>
          }
          
          {(gamesList && gamesList.size > 0) && lang != "" &&
            <div>
              <div class="chips">
                <div class="filterTitle">Филтрирайте:</div>

                {subscription == null &&
                  <div class="filter">
                    <div class="topic">По достъп:</div>
                    <div class="filters">
                      <Chip
                          onClick={() => this.onFilter("IsFree", true)}
                          label="Безплатен достъп"
                          className={isSelected("IsFree", true, this.state.filterProps, this.state.filterValues) ? "chip_selected" : "chip"}
                        />
                    </div>
                  </div>
                }

                <div class="filter">
                    <div class="topic">По предмет:</div>
                    <div class="filters">
                      {uniqueSubjects ?
                            uniqueSubjects.sort((a, b) => a.localeCompare(b)).map((subject) => {
                            return (
                              <Chip
                                onClick={() => this.onFilter("Subject", subject)}
                                key={subject}
                                label={subject}
                                className={isSelected("Subject", subject, this.state.filterProps, this.state.filterValues) ? "chip_selected" : "chip"}
                              />
                            );
                          }) : ''
                        }
                    </div>
                  </div>
                  
                  <div class="filter">
                    <div class="topic">По сложност:</div>
                    <div class="filters">
                    {uniqueComplexities ?
                          uniqueComplexities.sort((a, b) => a.localeCompare(b)).map((complexity) => {
                          var complexityLabel = "";
                          switch (complexity) {
                              case "1": {
                                complexityLabel = "Много лесно";
                                break;
                              }
                              case "2": {
                                complexityLabel = "Лесно";
                                break;
                              } 
                              case "3": {
                                complexityLabel = "Трудно";
                                break;
                              } 
                              case "4": {
                                complexityLabel = "Много трудно";
                                break;
                              } 
                          }
                          return (
                            <Chip
                              onClick={() => this.onFilter("Complexity", complexity)}
                              key={complexity}
                              label={complexityLabel}
                              className={isSelected("Complexity", complexity, this.state.filterProps, this.state.filterValues) ? "chip_selected" : "chip"}
                            />
                          );
                        }) : ''
                      } 
                    </div>
                  </div>

                  <div class="filter">
                    <Chip
                      {...(this.state.filterProps.length == 0) && {disabled:"true"}}
                      onClick={() => this.onClearFilter()}
                      label="Изчистете филтрите"
                      className="chip_clear"
                    />
                  </div>
            
              </div>
              <div class="table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                          {!isMobile &&
                            <TableCell align="center">
                              <TableSortLabel {...this.state.sortProp == "Id" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Id")}>Номер на играта</TableSortLabel>
                            </TableCell>
                          }
                          <TableCell align="center">
                            <TableSortLabel {...this.state.sortProp == "Title" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Title")}>Заглавие на играта</TableSortLabel>
                          </TableCell>
                          {!isMobile &&
                          <TableCell align="center">
                            <TableSortLabel {...this.state.sortProp == "Subject" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Subject")}>Предмет</TableSortLabel>
                          </TableCell>
                          }
                          {!isMobile &&
                            <TableCell align="center">
                              <TableSortLabel {...this.state.sortProp == "Complexity" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Complexity")}>Сложност</TableSortLabel>
                            </TableCell>
                          }
                          {!isMobile && profileId == null &&
                            <TableCell align="center">
                              <TableSortLabel {...this.state.sortProp == "Author" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("NumberOfPlayers")}>Автор</TableSortLabel>
                            </TableCell>
                          }
                          {!isMobile && profileId == null &&
                            <TableCell align="center">
                              <TableSortLabel {...this.state.sortProp == "Organization" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Organization")}>Организация</TableSortLabel>
                            </TableCell>
                          }
                          <TableCell align="center">Действие</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {gamesList ?
                          gamesList.sort((a, b) => sortGames(a, b, this.state.sortProp, this.state.desc)).filter(a => filterGames(a, this.state.filterProps, this.state.filterValues)).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((game) => {
                          var isPlayed;
                          var rowClassName = "";
                          if (profileId != null) {
                            isPlayed = (playedGames.indexOf(game.get('Id')) >= 0) ? "Играна е преди" : "Не е играна";
                            rowClassName = (playedGames.indexOf(game.get('Id')) >= 0) ? "isplayed" : "";
                          }
                          var complexity = "";
                          switch (game.get('Complexity')) {
                            case "1": 
                              complexity = "Много лесно";
                              break;
                            case "2": 
                              complexity = "Лесно";
                              break;
                            case "3": 
                              complexity = "Трудно";
                              break;
                            default:
                              complexity = "Много трудно";
                          }
                          return (
                            <TableRow className={rowClassName}>
                              {!isMobile &&
                                <TableCell align="center">{game.get('Id')}{(game.get('IsFree')) ? <div class="free_play"> (безплатен достъп)</div> : ""}</TableCell>
                              }
                              <TableCell align="center">{game.get('Title')}</TableCell>
                              {!isMobile && <TableCell align="center">{game.get('Subject')}</TableCell>}
                              {!isMobile && <TableCell align="center">{complexity}</TableCell>}
                              {!isMobile && profileId == null &&
                                <TableCell align="center">{(game.get('Author') == null) ? "-" : game.get('Author')}</TableCell>
                              }
                              {!isMobile && profileId == null &&
                                <TableCell align="center">{(game.get('Organization') == null) ? "-" : game.get('Organization')}</TableCell>
                              }
                              <TableCell align="center">
                                <FixedButton>
                                  <StandardButton text="Разгледайте играта" width="100%" href={'/game?id=' + game.get('Id')} />
                                </FixedButton>
                                {profileId != null && this.props.isLimitedToProfile &&
                                  <div>
                                    <FixedButton>
                                      <StandardButton text="Актуализирайте" width="100%" href={'/editgame?id=' + game.get('Id')} />
                                    </FixedButton>
                                  </div>
                                }
                                {profileId != null && (isActive || game.get('IsFree')) &&
                                  <div>
                                    <FixedButton>
                                      <StandardButton isDisabled={!(isActive || game.get('IsFree'))} text="Генерирайте код за игра" width="100%" href={'/createplaycode?gameId=' + game.get('Id')} type="generate"/>
                                    </FixedButton>
                                  </div>
                                }
                              </TableCell>
                            </TableRow>
                          );
                        }) : '-'
                    }           
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination 
                          rowsPerPageOptions={[5, 10, 25]}
                          count={gamesList.filter(a => filterGames(a, this.state.filterProps, this.state.filterValues)).size}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>
            }
        </div>
        }
        {profileId != null &&
              <div class="buttons">
                {profileId != null && this.props.isLimitedToProfile != null &&
                  <div class="create">
                    <FixedButton>
                      <StandardButton href="/editgame" text="Създайте игра" width="100%"/>
                    </FixedButton>
                  </div>
                }

                {profileId != null &&
                  <div class="back">
                    <FixedButton>
                      <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                    </FixedButton>
                  </div>
                }
              </div>
            }
      </StyledGames>
    )
  }
}

Games.propTypes = {
  games: PropTypes.object,
  profile: PropTypes.object,
  isLimitedToProfile: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    games: getGames(state),
    profile: getProfile(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchGames: () => dispatch(readGames()),
    fetchReadProfileGames: (profileId) => dispatch(readProfileGames(profileId)),
    fetchResetPlayCode: () => dispatch(resetPlayCode()),
    fetchResetGame: () => dispatch(resetGame()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Games)
