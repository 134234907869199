
import { REHYDRATE } from 'redux-persist/lib/constants'

export const REDUX_PERSIST_REHYDRATE = REHYDRATE

export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST'
export const CREATE_PROFILE_RESPONSE = 'CREATE_PROFILE_RESPONSE'
export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR'

export const READ_PROFILE_REQUEST = 'READ_PROFILE_REQUEST'
export const READ_PROFILE_RESPONSE = 'READ_PROFILE_RESPONSE'
export const READ_PROFILE_ERROR = 'READ_PROFILE_ERROR'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_RESPONSE = 'UPDATE_PROFILE_RESPONSE'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const CANCEL_PROFILE_SUBSCRIPTION_REQUEST = 'CANCEL_PROFILE_SUBSCRIPTION_REQUEST'
export const CANCEL_PROFILE_SUBSCRIPTION_RESPONSE = 'CANCEL_PROFILE_SUBSCRIPTION_RESPONSE'
export const CANCEL_PROFILE_SUBSCRIPTION_ERROR = 'CANCEL_PROFILE_SUBSCRIPTION_ERROR'

export const REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST = 'REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST'
export const REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE = 'REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE'
export const REACTIVATE_PROFILE_SUBSCRIPTION_ERROR = 'REACTIVATE_PROFILE_SUBSCRIPTION_ERROR'

export const UPGRADE_PROFILE_SUBSCRIPTION_REQUEST = 'UPGRADE_PROFILE_SUBSCRIPTION_REQUEST'
export const UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE = 'UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE'
export const UPGRADE_PROFILE_SUBSCRIPTION_ERROR = 'UPGRADE_PROFILE_SUBSCRIPTION_ERROR'

export const REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST = 'REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST'
export const REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE = 'REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE'
export const REPURCHASE_PROFILE_SUBSCRIPTION_ERROR = 'REPURCHASE_PROFILE_SUBSCRIPTION_ERROR'

export const CREATE_GAME_REQUEST = 'CREATE_GAME_REQUEST'
export const CREATE_GAME_RESPONSE = 'CREATE_GAME_RESPONSE'
export const CREATE_GAME_ERROR = 'CREATE_GAME_ERROR'

export const READ_PROFILE_GAMES_REQUEST = 'READ_PROFILE_GAMES_REQUEST'
export const READ_PROFILE_GAMES_RESPONSE = 'READ_PROFILE_GAMES_RESPONSE'
export const READ_PROFILE_GAMES_ERROR = 'READ_PROFILE_GAMES_ERROR'

export const READ_GAME_REQUEST = 'READ_GAME_REQUEST'
export const READ_GAME_RESPONSE = 'READ_GAME_RESPONSE'
export const READ_GAME_ERROR = 'READ_GAME_ERROR'

export const READ_GAME_BY_ACCESS_KEY_REQUEST = 'READ_GAME_BY_ACCESS_KEY_REQUEST'
export const READ_GAME_BY_ACCESS_KEY_RESPONSE = 'READ_GAME_BY_ACCESS_KEY_RESPONSE'
export const READ_GAME_BY_ACCESS_KEY_ERROR = 'READ_GAME_BY_ACCESS_KEY_ERROR'

export const UPDATE_GAME_REQUEST = 'UPDATE_GAME_REQUEST'
export const UPDATE_GAME_RESPONSE = 'UPDATE_GAME_RESPONSE'
export const UPDATE_GAME_ERROR = 'UPDATE_GAME_ERROR'

export const DELETE_GAME_REQUEST = 'DELETE_GAME_REQUEST'
export const DELETE_GAME_RESPONSE = 'DELETE_GAME_RESPONSE'
export const DELETE_GAME_ERROR = 'DELETE_GAME_ERROR'

export const DUPLICATE_GAME_REQUEST = 'DUPLICATE_GAME_REQUEST'
export const DUPLICATE_GAME_RESPONSE = 'DUPLICATE_GAME_RESPONSE'
export const DUPLICATE_GAME_ERROR = 'DUPLICATE_GAME_ERROR'

export const RESET_GAME = 'RESET_GAME'

export const ADD_LEVEL_REQUEST = 'ADD_LEVEL_REQUEST'
export const ADD_LEVEL_RESPONSE = 'ADD_LEVEL_RESPONSE'
export const ADD_LEVEL_ERROR = 'ADD_LEVEL_ERROR'

export const UPDATE_LEVEL_REQUEST = 'UPDATE_LEVEL_REQUEST'
export const UPDATE_LEVEL_RESPONSE = 'UPDATE_LEVEL_RESPONSE'
export const UPDATE_LEVEL_ERROR = 'UPDATE_LEVEL_ERROR'

export const DELETE_LEVEL_REQUEST = 'DELETE_LEVEL_REQUEST'
export const DELETE_LEVEL_RESPONSE = 'DELETE_LEVEL_RESPONSE'
export const DELETE_LEVEL_ERROR = 'DELETE_LEVEL_ERROR'

export const READ_GAMES_REQUEST = 'READ_GAMES_REQUEST'
export const READ_GAMES_RESPONSE = 'READ_GAMES_RESPONSE'
export const READ_GAMES_ERROR = 'READ_GAMES_ERROR'

export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST'
export const FORGOTTEN_PASSWORD_RESPONSE = 'FORGOTTEN_PASSWORD_RESPONSE'
export const FORGOTTEN_PASSWORD_ERROR = 'FORGOTTEN_PASSWORD_ERROR'

export const CREATE_PLAYCODE_REQUEST = 'CREATE_PLAYCODE_REQUEST'
export const CREATE_PLAYCODE_RESPONSE = 'CREATE_PLAYCODE_RESPONSE'
export const CREATE_PLAYCODE_ERROR = 'CREATE_PLAYCODE_ERROR'

export const READ_PLAYCODE_REQUEST = 'READ_PLAYCODE_REQUEST'
export const READ_PLAYCODE_RESPONSE = 'READ_PLAYCODE_RESPONSE'
export const READ_PLAYCODE_ERROR = 'READ_PLAYCODE_ERROR'

export const READ_PLAYCODES_REQUEST = 'READ_PLAYCODES_REQUEST'
export const READ_PLAYCODES_RESPONSE = 'READ_PLAYCODES_RESPONSE'
export const READ_PLAYCODES_ERROR = 'READ_PLAYCODES_ERROR'

export const CREATE_PROFILE_SUBSCRIPTION_REQUEST = 'CREATE_PROFILE_SUBSCRIPTION_REQUEST'
export const CREATE_PROFILE_SUBSCRIPTION_RESPONSE = 'CREATE_PROFILE_SUBSCRIPTION_RESPONSE'
export const CREATE_PROFILE_SUBSCRIPTION_ERROR = 'CREATE_PROFILE_SUBSCRIPTION_ERROR'

export const RESET_PLAYCODE = 'RESET_PLAYCODE'

export const READ_LAST_PLAYCODE_REQUEST = 'READ_LAST_PLAYCODE_REQUEST'
export const READ_LAST_PLAYCODE_RESPONSE = 'READ_LAST_PLAYCODE_RESPONSE'
export const READ_LAST_PLAYCODE_ERROR = 'READ_LAST_PLAYCODE_ERROR'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'